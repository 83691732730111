import GENERAL_DIRECTIVES from '../generalDirectives';

export const ImExec = {
    label: 'External Program',
    type: 'collect',
    name: 'im_exec',
    defaultDirectives: `Module      im_exec`,
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Command',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Arg',
            type: 'string',
            multiple: true,
            defaultValue: []
        },
        {
            name: 'Restart',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
