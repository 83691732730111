import GENERAL_DIRECTIVES from '../generalDirectives';

export const OmAzuremonitor = {
    label: 'Microsoft Azure Log Ingestion',
    name: 'om_azuremonitor',
    type: 'sendTo',
    defaultDirectives: 'Module      om_azuremonitor',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'ClientId',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'TenantId',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'ClientSecret',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertKeyFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'DcrImmutableId',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'URL',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'StreamName',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Reconnect',
            type: 'number',
            defaultValue: ''
        },
        {
            name: 'AzureBatchSize',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Connections',
            type: 'number',
            defaultValue: 1
        },
        {
            name: 'AuthURL',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertPattern',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'UseCNGCertificates',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
