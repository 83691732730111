import GENERAL_DIRECTIVES from '../generalDirectives';

export const OmSsl = {
    label: 'TLS/SSL Transport',
    type: 'sendTo',
    name: 'om_ssl',
    defaultDirectives: 'Module      om_ssl',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Host',
            type: 'string',
            required: true,
            multiple: true,
            defaultValue: ''
        },
        {
            name: 'Port',
            type: 'number',
            required: true,
            requiredInConfigText: true,
            value: 514,
            defaultValue: 514
        },
        {
            name: 'AllowExpired',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'AllowUntrusted',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'CADir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CAFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CAThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SearchAllCertStores',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'CertFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertKeyFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CRLDir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CRLFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'DHFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'KeyPass',
            type: 'string',
            defaultValue: ''
        },

        {
            name: 'LocalPort',
            type: 'number',
            defaultValue: ''
        },
        {
            name: 'Reconnect',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SNI',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SSLCipher',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SSLCiphersuites',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SSLCompression',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'SSLProtocol',
            type: 'select',
            options: ['SSLv2', 'SSLv3', 'TLSv1', 'TLSv1.1', 'TLSv1.2', 'TLSv1.3'],
            defaultValue: [],
            multiple: true,
            separator: ', '
        },
        {
            name: 'TCPNoDelay',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'CAPattern',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertPattern',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'UseCNGCertificates',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        ...GENERAL_DIRECTIVES
    ]
};
export default null;
