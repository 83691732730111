import GENERAL_DIRECTIVES from '../generalDirectives';

export const ImRedis = {
    label: 'Redis',
    name: 'im_redis',
    type: 'collect',
    defaultDirectives: 'Module      im_redis',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Host',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'Channel',
            type: 'string',
            defaultValue: [],
            multiple: true
        },
        {
            name: 'Command',
            type: 'select',
            options: ['LPOP', 'RPOP', 'SUBSCRIBE', 'PSUBSCRIBE'],
            defaultValue: 'LPOP'
        },
        {
            if: (item) => ['SUBSCRIBE', 'PSUBSCRIBE'].includes(item.Command),
            name: 'Key',
            type: 'string',
            defaultValue: 'nxlog'
        },
        {
            name: 'LocalPort',
            type: 'number',
            defaultValue: ''
        },
        {
            name: 'PollInterval',
            type: 'number',
            defaultValue: 1
        },
        {
            name: 'Port',
            type: 'number',
            defaultValue: 6379
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
