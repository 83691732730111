import GENERAL_DIRECTIVES from '../generalDirectives';

export const OmBatchcompressNXLogPlatform = {
    label: 'NXLog Platform',
    name: 'om_batchcompress_nxlog_platform',
    type: 'sendTo',
    defaultDirectives: 'Module      om_batchcompress',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Host',
            type: 'string',
            defaultValue: [{ value: '' }],
            multiple: true,
            required: true
        },
        {
            name: 'Port',
            type: 'number',
            defaultValue: 2514
        },
        {
            name: 'AllowExpired',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'AllowUntrusted',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'CADir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CAFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CAThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SearchAllCertStores',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'CertFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertKeyFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CRLDir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CRLFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'DHFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'KeyPass',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'LocalPort',
            type: 'number',
            defaultValue: ''
        },
        {
            name: 'Reconnect',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SSLCipher',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SSLCiphersuites',
            type: 'string',
            defaultValue:
                'TLS_AES_256_GCM_SHA384:TLS_CHACHA20_POLY1305_SHA256:TLS_AES_128_GCM_SHA256'
        },
        {
            name: 'SSLProtocol',
            type: 'select',
            options: ['SSLv2', 'SSLv3', 'TLSv1', 'TLSv1.1', 'TLSv1.2', 'TLSv1.3'],
            defaultValue: [],
            multiple: true,
            separator: ', '
        },
        {
            name: 'UseSSL',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'CAPattern',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertPattern',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'UseCNGCertificates',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'LogqueueSize',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'PersistLogqueue',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
