import { cloneDeep, get, set } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { AGENT_SELECTOR, EMPTY_ROUTE, SORT_ORDER } from '../../constants/ids';

export function capitalizeFirstLetter(string = '') {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function decapitalizeFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

export function parseAgentResourse(string) {
    const split = string.split('->');
    const [fileName, fileContent] = split.map((s) => {
        let str = s.trim();
        if (str.at(0) === "'" && str.at(-1) === "'") {
            str = str.slice(1, -1);
        }
        return str;
    });
    return [fileName, fileContent];
}

export function parseConfigString(string) {
    const res = {};

    let arr = string?.split(/[\n\r]+/);
    if (arr?.length <= 2) {
        arr = string?.split('\\n');
    }
    const stack = [];
    arr?.forEach((item) => {
        if (item.includes('<') && !item.includes('/')) {
            const text = item?.replaceAll(/[</>]/gi, '')?.trim();
            if (text === 'Exec') {
                stack.push('execRules');
            } else {
                stack.push(text);
            }
        }
        if (item.includes('<') && item.includes('/')) {
            stack.pop();
        }
        if (!item.includes('<')) {
            if (stack[stack.length - 1] === 'execRules') {
                const execRules = get(res, `${stack.join('.')}`);
                set(res, `${stack.join('.')}`, execRules ? [...execRules, item] : [item]);
            } else {
                const splitedString = item?.split(' ')?.filter((n) => n);
                const [name, ...value] = splitedString;
                if (stack.length) {
                    if (name === 'Host' || name === 'ListenAddr' || name === 'Connect') {
                        const connect = get(res, `${stack.join('.')}`);
                        set(
                            res,
                            `${stack.join('.')}.${name}`,
                            connect[name]
                                ? (connect[name] = [...connect[name], value.join('')])
                                : (connect[name] = [value.join('')])
                        );
                    } else {
                        set(res, `${stack.join('.')}.${name}`, value.join(''));
                    }
                } else if (!name?.startsWith('#')) {
                    res[name] = value.join('');
                }
            }
        }
    });
    return res;
}

export function parseModules(fileContent, tagName) {
    const tagPattern = new RegExp(`<${tagName}\\b[^>]*>([\\s\\S]*?)<\\/${tagName}>`, 'g');
    const propertyPattern = /(\w+)\s+([^=\s]+|".*?"|'.*?')/g;
    const tagProperties = [];

    let tagMatch = tagPattern.exec(fileContent);
    while (tagMatch !== null) {
        const properties = {};
        const propertyText = tagMatch[1];

        let propertyMatch = propertyPattern.exec(propertyText);
        while (propertyMatch !== null) {
            const propertyName = propertyMatch[1].trim();
            const propertyValue = propertyMatch[2].trim().replace(/^['"]|['"]$/g, '');
            properties[propertyName] = propertyValue;

            propertyMatch = propertyPattern.exec(propertyText);
        }

        tagProperties.push(properties);
        tagMatch = tagPattern.exec(fileContent);
    }

    return tagProperties;
}

export const createSortString = (props) => {
    const queryArr = [];
    props.forEach(({ order, name }) => {
        if (order === SORT_ORDER.ASC) {
            queryArr.push(`${name}`);
        } else {
            queryArr.push(`${name} desc`);
        }
    });

    const sortQuery = `${queryArr.join(',')}`;
    return sortQuery;
};

export const changeSortOrder = (order) => {
    if (order === SORT_ORDER.ASC) {
        return SORT_ORDER.DESC;
    }
    return SORT_ORDER.ASC;
};

const stringFromRulesArray = (rules) => {
    let res = '';
    rules.forEach(([key, value]) => {
        let temp = '';
        const valueSet = [...new Set(value)];

        const excludingValue = valueSet.find(
            (string) => typeof string === 'string' && string.toLowerCase().slice(0, 4) === 'not '
            // processes the rules like 'not = ...' or 'not in ...'
        );
        if (excludingValue) {
            if (valueSet.length > 1) {
                const rest = valueSet.filter((str) => str !== excludingValue);
                const restString = stringFromRulesArray([[key, rest]]);
                temp = `${key} ${excludingValue} or ${restString}`;
            } else {
                temp = `${key} ${excludingValue}`;
            }
        } else if (valueSet.length > 1) {
            const valueSetWithIs = valueSet.filter((val) =>
                `${val}`.trim().toLowerCase().startsWith('is ')
            );
            const valueSetWithNoIs = valueSet.filter(
                (val) => !`${val}`.trim().toLowerCase().startsWith('is ')
            );
            const tmpFilter = [];
            if (valueSetWithIs.length) {
                tmpFilter.push(...valueSetWithIs.map((val) => `${key} ${val}`));
            }
            if (valueSetWithNoIs.length > 1) {
                tmpFilter.push(`${key} in (${valueSetWithNoIs.join(', ')})`);
            } else if (valueSetWithNoIs.length) {
                tmpFilter.push(`${key} = ${valueSetWithNoIs[0]}`);
            }
            if (tmpFilter.length > 1) {
                temp = `(${tmpFilter.join(') or (')})`;
            } else if (tmpFilter.length) {
                temp = `${tmpFilter[0]}`;
            }
        } else if (valueSet[0] && `${valueSet[0]}`.trim().toLowerCase().startsWith('is ')) {
            temp = `${key} ${valueSet[0]}`;
        } else {
            temp = `${key} = ${valueSet[0]}`;
        }
        if (res.length) {
            res += ` and ${temp}`;
            // unites multiple rules inside one filter with 'and'
            // {filter: {x: 'a', y: 'b'}} resolves to 'x = a and y = b'
        } else {
            res += temp;
        }
    });
    return res;
};

const addRulesToMap = (map, filter) => {
    Object.entries(filter).forEach(([key, value]) => {
        if (map.get(key)) {
            map.set(key, [...map.get(key), value]);
        } else {
            map.set(key, [value]);
        }
    });
};

const createSingleFilterGroupString = (f) => {
    let filterString = '';

    const stringFilters = f.filter((fil) => typeof fil.filter === 'string');
    const simpleFilters = f.filter(
        (fil) => typeof fil.filter !== 'string' && Object.entries(fil.filter).length === 1
    );
    const complexFilters = f.filter(
        (fil) => typeof fil.filter !== 'string' && Object.entries(fil.filter).length > 1
    );

    const simpleMap = new Map();
    simpleFilters.forEach(({ filter }) => {
        addRulesToMap(simpleMap, filter);
    });

    filterString = stringFromRulesArray(Array.from(simpleMap.entries()));

    const complexArray = [];

    complexFilters.forEach(({ filter }) => {
        const map = new Map();
        addRulesToMap(map, filter);
        complexArray.push(`(${stringFromRulesArray(Array.from(map.entries()))})`);
    });

    if (filterString.length > 0) {
        filterString += complexArray.length ? ` or (${complexArray.join(' or ')})` : '';
    } else {
        filterString = complexArray.join(' or ');
    }
    if (stringFilters.length) {
        const string = stringFilters.map((s) => s.filter).join(' or ');
        filterString = filterString.length ? filterString + string : string;
    }

    return filterString ? `(${filterString})` : filterString;
};

export const createFilterString = (f) => {
    const filtersByGroup = f.reduce((acc, filter) => {
        const groupId = filter.filterGroupId ?? 'no_group';
        acc[groupId] = [...(acc[groupId] || []), filter];
        return acc;
    }, {});

    return Object.values(filtersByGroup).map(createSingleFilterGroupString).join(' and ');
};

export const createAdditionalConfigString = (configContent) => {
    const arr = [];
    const start = configContent.indexOf('<Extension admin>');
    if (start === -1) {
        const confgitWithoutLogData = configContent.split('\n').slice(2).join('\n');
        return confgitWithoutLogData;
    }
    const end = configContent.indexOf('</Extension>', start);
    const str = configContent.slice(0, start) + configContent.slice(end + 14);

    str?.split('\n')?.forEach((item) => {
        if (!(item.indexOf('LogLevel') !== -1 || item.indexOf('LogFile') !== -1)) {
            arr.push(item);
        }
    });

    return arr.join('\n');
};

export const createExcludedIdsFilter = (ids, strFilter = null, idField = AGENT_SELECTOR) => {
    const excludedIds = ids.filter((id) => id !== '*');
    if (excludedIds.length) {
        const excludedIdsFilter =
            excludedIds.length === 1
                ? `${idField} not = ${excludedIds[0]}`
                : `${idField} not in (${excludedIds.join(',')})`;
        if (strFilter) {
            return `(${strFilter}) and (${excludedIdsFilter})`;
        }
        return excludedIdsFilter;
    }
    return strFilter;
};

export const createIncludedIdsFilter = (ids, strFilter = null, idField = AGENT_SELECTOR) => {
    if (ids.length) {
        const includedIdsFilter =
            ids.length === 1 ? `${idField} = ${ids[0]}` : `${idField} in (${ids.join(',')})`;
        if (strFilter) {
            return `(${strFilter}) and (${includedIdsFilter})`;
        }
        return includedIdsFilter;
    }
    return strFilter;
};

export const createFilterStringFromSelectedIds = (
    selectedEntries,
    strFilter = null,
    idField = AGENT_SELECTOR
) => {
    const ids = selectedEntries.map((agent) => `${agent.id}`);
    if (ids.includes('*')) {
        return createExcludedIdsFilter(ids, strFilter, idField);
    }
    return createIncludedIdsFilter(ids, strFilter, idField);
};

const trimBrackets = (address) => {
    const i = address.length - 1;
    return address[0] === '[' && address[i] === ']' ? address.slice(1, i) : address;
};

export const addressPortSplit = (hostStr) => {
    if (typeof hostStr !== 'string') return [];
    const index = hostStr.lastIndexOf(':');
    if (index >= 0) {
        const stringStart = hostStr.slice(0, index);
        if (!stringStart.match(/^https?$/)) {
            const address = trimBrackets(stringStart);
            const port = hostStr.slice(index + 1);
            return [address, port];
        }
    }
    const address = trimBrackets(hostStr);
    return [address, ''];
};

export const createFormTemplateConfig = (configContent) => {
    const str = parseConfigString(configContent);
    const extensionModules = [];
    const globalConfig = {};
    globalConfig.logLevel = str?.LogLevel;
    const localRoutes = new Map();
    let connectAddress = '';

    Object.entries(str).forEach(([key, value]) => {
        if (typeof value === 'object') {
            const [type, name] = key.split(' ');
            if (type === 'Route') {
                let route = cloneDeep(EMPTY_ROUTE);
                route.id = uuidv4();
                route = {
                    ...route,
                    name,
                    modulesPath: value?.Path?.split(/[,=> ]+/)
                };
                localRoutes.set(route.id, { ...route });
            }
            if (type === 'Extension') {
                if (str[key].ListenAddr) {
                    globalConfig.connectType = 'listen';
                    str[key].ListenAddr.forEach((ip) => {
                        const [address, port] = addressPortSplit(ip);
                        globalConfig[`host-${address}`] = address;
                        globalConfig[`port-${address}`] = port;
                    });
                }
                if (str[key].Host) {
                    globalConfig.connectType = 'connect';
                    str[key].Host.forEach((ip) => {
                        const [address, port] = addressPortSplit(ip);
                        globalConfig[`host-${address}`] = address;
                        globalConfig[`port-${address}`] = port;
                    });
                }
                if (str[key].Connect) {
                    globalConfig.connectType = 'connect';
                    str[key].Connect.forEach((ip) => {
                        const [address, port] = addressPortSplit(ip);
                        connectAddress = address;
                        globalConfig[`host-${address}`] = address;
                        globalConfig[`port-${address}`] = port;
                    });
                }
                if (str[key].Port) {
                    globalConfig[`port-${connectAddress}`] = str[key].Port;
                }
            }
        }
    });

    const createModule = (data, routeId) => {
        const route = localRoutes.get(routeId);
        const { moduleType, ...moduleData } = data;
        const modules = route.modules[moduleType]?.data || [];

        if (!route.modules[moduleType]) {
            route.modules[moduleType] = { data: [] };
        }

        const module = {
            id: uuidv4(),
            ...moduleData
        };

        route.modules[moduleType].data = [...modules, module];
        localRoutes.set(routeId, route);
    };

    Object.entries(str).forEach(([key, value]) => {
        if (typeof value === 'object') {
            const [type, name] = key.split(' ');
            const currentRoute = Array.from(localRoutes.values())?.find((item) =>
                item?.modulesPath?.includes(name)
            );
            const moduleData = {};
            Object.keys(value).forEach((objKey) => {
                if (objKey === 'ListenAddr') {
                    moduleData.connectType = 'listen';
                    value.ListenAddr.forEach((ip) => {
                        const [address, port] = addressPortSplit(ip);
                        moduleData[`host-${address}`] = address;
                        moduleData[`port-${address}`] = port;
                    });
                } else if (objKey === 'Host') {
                    moduleData.connectType = 'connect';
                    value.Host.forEach((ip) => {
                        const [address, port] = addressPortSplit(ip);
                        moduleData[`host-${address}`] = address;
                        moduleData[`port-${address}`] = port;
                    });
                } else {
                    moduleData[decapitalizeFirstLetter(objKey)] = value[objKey];
                }
            });
            if (currentRoute?.id && type !== 'Route') {
                createModule(
                    {
                        name,
                        moduleType: type.toLowerCase(),
                        ...moduleData
                    },
                    currentRoute?.id
                );
            }
            if (type === 'Extension' && name !== 'admin') {
                const module = {
                    id: uuidv4(),
                    name,
                    ...moduleData
                };
                extensionModules.push(module);
            }
        }
    });

    return { localRoutes, globalConfig, extensionModules };
};

export function agentTagNormalizer(tag) {
    tag = String(tag).toLowerCase();

    const hostname = [
        'hostname',
        'agent name',
        'agentname',
        'agent-name',
        'agent_name',
        'agent',
        'name'
    ];
    const address = ['ip', 'ip address', 'ipaddress', 'ip-address', 'ip_address', 'address'];
    const agentEps = ['events_per_second', 'agentEps', 'eps'];
    const memoryUsed = [
        'memory_used',
        'memory',
        'memory use',
        'memoryuse',
        'memory-use',
        'memory_use'
    ];
    const cpuLoad = ['cpu_load', 'load'];
    const enrolled = [
        'enrolled',
        'deployment',
        'deployment state',
        'deploymentstate',
        'deployment-state',
        'deployment_state'
    ];
    const agentGroup = [
        'agentGroup',
        'agent group',
        'agentgroup',
        'agent-group',
        'agent_group',
        'group'
    ];
    const templateName = ['template-name', 'template_name', 'templateName', 'template'];

    if (hostname.includes(tag)) return 'hostname';
    if (address.includes(tag)) return 'ip_address';
    if (agentEps.includes(tag)) return 'events_per_second';
    if (memoryUsed.includes(tag)) return 'memory_used';
    if (cpuLoad.includes(tag)) return 'cpu_load';
    if (enrolled.includes(tag)) return 'enrolled';
    if (agentGroup.includes(tag)) return 'agentGroup';
    if (templateName.includes(tag)) return 'template-name';

    return tag;
}

export function clusterTagNormalizer(tag) {
    tag = String(tag).toLowerCase();

    const clustername = ['name', 'cluster name', 'clustername', 'cluster-name', 'cluster_name'];
    const roleOfMembers = ['role', 'role_of_members'];
    const memoryPercentage = ['memory_percentage', 'memory'];
    const cpuPercentage = ['cpu_percentage', 'cpu'];
    const agentConnections = ['agent_connections', 'connections'];

    if (clustername.includes(tag)) return 'clustername';
    if (roleOfMembers.includes(tag)) return 'role_of_members';
    if (memoryPercentage.includes(tag)) return 'memory';
    if (cpuPercentage.includes(tag)) return 'cpu_percentage';
    if (agentConnections.includes(tag)) return 'agent_connections';

    return tag;
}

export function templateTagNormalizer(tag) {
    tag = String(tag).toLowerCase();

    const name = [
        'configuration name',
        'configurationname',
        'configuration-name',
        'configuration_name',
        'configuration',
        'config name',
        'configname',
        'config-name',
        'config_name',
        'config',
        'template name',
        'templatename',
        'template-name',
        'template_name',
        'template',
        'name'
    ];
    const agent = [
        'used',
        'use',
        'used_by_agents',
        'agent-use',
        'agent_use',
        'used_by_agents',
        'agent',
        'agents'
    ];
    const content = ['module', 'content', 'contents'];
    const comment = ['comment', 'comments', 'info', 'describe', 'description'];

    if (name.includes(tag)) return 'name';
    if (agent.includes(tag)) return 'used-by-agents';
    if (content.includes(tag)) return 'content';
    if (comment.includes(tag)) return 'comment';

    return tag;
}

export function notificationTagNormalizer(tag) {
    const normalizedTag = String(tag).toLowerCase();

    const tagMappings = {
        'additional info': 'additionalinfo',
        info: 'additionalinfo',
        date: 'time'
    };

    return tagMappings[normalizedTag] || normalizedTag;
}

export function convertSearchTerms(searchTerm) {
    let comparator;
    const validComparators = [':', '=', '>', '<', '>=', '<='];

    validComparators.forEach((c) => {
        if (searchTerm.indexOf(c) !== -1) {
            comparator = c;
        }
    });
    if (!comparator) {
        return {
            tag: 'all',
            comparator: '=',
            searchText: searchTerm
        };
    }

    const [tag, searchText] = searchTerm.split(comparator);
    return {
        tag: searchText ? tag?.trim() : null,
        comparator: comparator?.trim(),
        searchText: (searchText || tag)?.trim()
    };
}

/**
 * Function substitutes curly braced params with their values
 * @param {string} text - the text to search and substitute params
 * @param {{[name: string]: string | number}} parametersObj - an object of parameter values
 */
export function substituteParameters(text, parametersObj) {
    return Object.entries(parametersObj).reduce(
        (acc, [name, value]) => acc.replaceAll(`{{${name}}}`, value),
        text
    );
}
