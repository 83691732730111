import GENERAL_DIRECTIVES from '../generalDirectives';

export const ImFile = {
    label: 'File',
    type: 'collect',
    name: 'im_file',
    defaultDirectives: `Module      im_file`,
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'File',
            type: 'string',
            quoting: true,
            required: true,
            defaultValue: ''
        },
        {
            name: 'ActiveFiles',
            type: 'number',
            defaultValue: 10
        },
        {
            name: 'CloseWhenIdle',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'DirCheckInterval',
            type: 'number',
            defaultValue: (item) => item.PollInterval * 2
        },
        {
            name: 'Exclude',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'NoEscape',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'PollInterval',
            type: 'number',
            defaultValue: 1
        },
        {
            name: 'ReadFromLast',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'ReadOrder',
            type: 'select',
            options: [
                'none',
                'CtimeOldestFirst',
                'CtimeNewestFirst',
                'MtimeOldestFirst',
                'MtimeNewestFirst',
                'NameAsc',
                'NameDesc'
            ],
            defaultValue: 'none'
        },
        {
            name: 'Recursive',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'SavePos',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'FollowSymlinks',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
