import classNames from 'classnames';
import React from 'react';
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { useFormContext, get } from 'react-hook-form';
import Success from '@nxlog/common-ui/dist/components/svgs/success';
import './formInput.scss';

function FormInput(props) {
    const {
        label,
        classname,
        required,
        placeholder,
        name,
        type,
        pattern,
        validationProps,
        disabled,
        isSuccess
    } = props;

    const {
        register,
        formState: { errors }
    } = useFormContext();
    const { message } = get(errors, name) || {};
    const hasError = !!get(errors, name);

    return (
        <div
            data-testid="form-input"
            className={classNames('input-form-container', disabled && 'input-disabled', classname)}
        >
            <label data-testid="form-input-label" htmlFor={name}>
                {label}
                {required && (
                    <FontAwesomeIcon data-testid="form-input-asterisk" icon={faAsterisk} />
                )}
            </label>
            <input
                data-testid="form-input-control"
                name={name}
                disabled={disabled}
                className={classNames('input-form', hasError && 'input-error')}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register(name, {
                    ...validationProps,
                    required: required && 'required',
                    pattern
                })}
                placeholder={placeholder}
                type={type}
            />
            {isSuccess && (
                <div data-testid="form-input-success" className="success-wrapper">
                    <Success outlined fill="#228D3A" />
                </div>
            )}
            {Boolean(message) && (
                <span data-testid="form-input-error" className="error">
                    {message}
                </span>
            )}
        </div>
    );
}

FormInput.defaultProps = {
    label: '',
    classname: '',
    required: false,
    placeholder: '',
    type: 'text',
    pattern: '',
    disabled: false,
    isSuccess: false,
    validationProps: {}
};

FormInput.propTypes = {
    label: propTypes.string,
    classname: propTypes.string,
    required: propTypes.bool,
    placeholder: propTypes.string,
    pattern: propTypes.oneOfType([
        propTypes.string,
        propTypes.shape({
            value: propTypes.shape(RegExp),
            message: propTypes.string
        })
    ]),
    // eslint-disable-next-line react/forbid-prop-types
    validationProps: propTypes.object,
    name: propTypes.string.isRequired,
    disabled: propTypes.bool,
    isSuccess: propTypes.bool,
    type: propTypes.string
};

export default FormInput;
