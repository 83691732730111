import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import { createNotification } from '../helpers/functions';
import { addNotifications } from '../../redux/reducers/notifications';

export default function useSelections({
    currentPageIds,
    filterString,
    loadData,
    loadCount,
    params,
    actions,
    errorMessage,
    onAction,
    onRefresh
}) {
    const [checkedList, setCheckedList] = useState([]);
    const [rows, setRows] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const [page, setPage] = useState(1);
    const [selectType, setSelectType] = useState('none');
    const dispatch = useDispatch();

    const onSelectAllChange = (type) => {
        switch (type) {
            case 'page':
                setCheckedList(currentPageIds);
                break;
            default:
                setCheckedList([]);
                setRows([]);
                setCount(0);
                break;
        }
        setSelectType(type);
    };

    const onCheckedChanged = (id, checked) => {
        const $checked = selectType === 'all' ? !checked : checked;
        setCheckedList((prev) => {
            if ($checked) {
                return prev.includes(id) ? prev : [...prev, id];
            }
            return prev.filter((item) => item !== id);
        });
    };

    const isChecked = (id) =>
        selectType === 'all' ? !checkedList.includes(id) : checkedList.includes(id);

    let filter = [];
    if (filterString) {
        filter.push(filterString);
    }
    if (selectType === 'all') {
        if (checkedList.length > 0) {
            filter.push(`id not in (${checkedList.join(',')})`);
        }
    } else if (checkedList.length > 0) {
        filter.push(`id in (${checkedList.join(',')})`);
    }

    filter = filter.map((str) => `(${str})`).join(' and ');

    useEffect(() => {
        if (filter || selectType === 'all') {
            setLoading(true);
            Promise.all([
                loadData({
                    ...params,
                    filter,
                    offset: ((page || 1) - 1) * itemsPerPage,
                    limit: itemsPerPage
                }).then((res) => res.data),
                loadCount({ ...params, filter }).then((res) => res.data)
            ])
                .then(([_rows, _count]) => {
                    setRows(_rows);
                    setCount(_count);
                    if (!_count) onSelectAllChange('none');
                })
                .catch((error) => {
                    const additionalInfo =
                        error?.message?.message ||
                        (typeof error.message === 'string' ? error.message : '');
                    const notification = createNotification(
                        'error',
                        t(errorMessage),
                        additionalInfo
                    );
                    dispatch(addNotifications(notification));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [filter, selectType, page, itemsPerPage]);

    const handleSelectOption = (action) => {
        if (onAction) {
            setLoading(true);
            const forEach = (forEachCallback, successMessage, _errorMessage) =>
                loadData({ filter })
                    .then((res) => res.data)
                    .then((list) => Promise.allSettled(list.map(forEachCallback)))
                    .then((list) => {
                        const successCount = list.filter(
                            (item) => item.status === 'fulfilled'
                        ).length;
                        const errorCount = list.filter((item) => item.status === 'rejected').length;
                        if (successCount) {
                            const notification = createNotification(
                                'success',
                                t(successMessage, { count: successCount })
                            );
                            dispatch(addNotifications(notification));
                        }
                        if (errorCount) {
                            const notification = createNotification(
                                'error',
                                t(_errorMessage, { count: errorCount })
                            );
                            dispatch(addNotifications(notification));
                        }
                    });
            onAction(action, filter, forEach)
                .then((res) => {
                    if (res?.messages && Array.isArray(res?.messages)) {
                        res.messages.forEach(({ type, message }) =>
                            dispatch(addNotifications(createNotification(type, message)))
                        );
                    }
                })
                .catch((error) => {
                    const actionErrorMessage =
                        error?.message?.message ||
                        (typeof error.message === 'string' ? error.message : '');
                    const notification = createNotification('error', actionErrorMessage);
                    dispatch(addNotifications(notification));
                })
                .finally(() => {
                    if (onRefresh) onRefresh();
                    setLoading(false);
                    onSelectAllChange('none');
                });
        }
    };

    return {
        selectedAll:
            (loading || count) &&
            (selectType === 'all' || (selectType === 'page' && currentPageIds.every(isChecked))),
        openSelectModal: (loading || count) && (selectType === 'all' || checkedList.length > 0),
        onSelectAllChange,
        onCheckedChanged,
        isChecked,
        checkedList,
        filter,
        rows,
        count,
        actions,
        handleSelectOption,
        itemsPerPage,
        setItemsPerPage,
        setPage,
        page,
        loading
    };
}
