import { useState, useEffect } from 'react';
import SwaggerUI from 'swagger-ui-react';
import { getOpenAPISpecs } from '../../api/endpoints/utils';
import { getBaseUrl, getOrgId } from '../../utils/helpers/functions';
import { substituteParameters } from '../../utils/helpers/strings';
import useEnrollmentAddress from '../../utils/hooks/useEnrollmentAddress';
import './swagger.scss';

const BASE_URL = getBaseUrl();

// adding orgId to base URL
const orgId = getOrgId();
const baseURL = orgId ? `${BASE_URL}/${orgId}` : BASE_URL;

const reqInterceptor = (request) => {
    // changing default API url to the actual one
    const { url } = request;
    const urlObject = new URL(url);
    urlObject.host = baseURL;
    const newUrl = baseURL + urlObject.pathname + urlObject.search;
    request.url = newUrl;

    const userName = window?.env?.REACT_APP_USERNAME ?? process.env.REACT_APP_USERNAME;
    const userPass = window?.env?.REACT_APP_USERPASS ?? process.env.REACT_APP_USERPASS;

    // adding basic auth if username and password provided
    if (!!userName && !!userPass) {
        request.headers.Authorization = `Basic ${btoa(`${userName}:${userPass}`)}`;
    }

    return request;
};

export default function Swagger() {
    const [apiSpec, setAPISpec] = useState();
    const enrollmentHost = useEnrollmentAddress();

    const paramValues = {
        agents_endpoint: enrollmentHost
    };

    useEffect(() => {
        getOpenAPISpecs()
            .then((spec) => {
                const completeSpec = substituteParameters(spec, paramValues);
                setAPISpec(completeSpec);
            })
            .catch(() => {
                // do nothing
            });
    }, []);

    return apiSpec ? (
        <SwaggerUI
            spec={apiSpec}
            requestInterceptor={reqInterceptor}
            defaultModelsExpandDepth={-1} // this hides schemas
        />
    ) : (
        <div className="align-center">
            <p>Waiting for API to answer...</p>
        </div>
    );
}
