import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateNotificationsCount } from '../../redux/reducers/notifications';
import { selectItemsPerPageValue } from '../../redux/reducers/agents';
import TableNotifications from '../../components/tables/tableNotifications';
import SearchNotification from '../../components/searchNotifications';
import { STORAGE_KEYS } from '../../utils/constants/ids';
import { selectValueFromStorage } from '../../redux/reducers/storage';

export default function NotificationsPage() {
    const dispatch = useDispatch();
    const instanceId = useSelector(selectValueFromStorage(STORAGE_KEYS.INSTANCE_ID));
    const [allData, setAllData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState({ label: 1, value: 1 });
    const [checkedRows, setCheckedRows] = useState(new Map());

    useEffect(() => {
        dispatch(updateNotificationsCount(allData.length));
    }, [allData, tableData]);

    useEffect(() => {
        if (instanceId) {
            const savedInstanceId = sessionStorage.getItem(STORAGE_KEYS.INSTANCE_ID);
            if (instanceId !== savedInstanceId) {
                sessionStorage.removeItem('notifications');
                sessionStorage.setItem(STORAGE_KEYS.INSTANCE_ID, instanceId);
            }
            let notificationData = [];
            try {
                notificationData = JSON.parse(
                    sessionStorage.getItem('notifications')?.replace(/\[settings_link\]/g, '') ||
                        '[]'
                );
            } catch (e) {
                /* */
            }
            if (Array.isArray(notificationData)) {
                setAllData(notificationData);
                setTableData(notificationData);
            }
        }
    }, [instanceId]);

    const handleSetCheckedRows = useCallback(
        (recordArray) => {
            setCheckedRows(new Map(recordArray.map((r) => [r.id, r])));
        },
        [setCheckedRows]
    );

    const itemsPerPage = useSelector(selectItemsPerPageValue);
    const isFirstPage = currentPage.value === 1;
    const paginationIndex = isFirstPage ? 0 : itemsPerPage * (currentPage.value - 1);
    const clonedArray = tableData?.slice();
    const paginatedTableData = clonedArray?.splice(paginationIndex, itemsPerPage);

    return (
        <div className="notifications-page w-100">
            <SearchNotification
                checkedRows={checkedRows}
                setCheckedRows={handleSetCheckedRows}
                allData={allData}
                tableData={tableData}
                paginatedTableData={paginatedTableData}
                setTableData={setTableData}
            />
            <TableNotifications
                allData={allData}
                tableData={tableData}
                checkedRows={checkedRows}
                setCheckedRows={handleSetCheckedRows}
                setTableData={setTableData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginatedTableData={paginatedTableData}
            />
        </div>
    );
}
