import GENERAL_DIRECTIVES from '../generalDirectives';

export const OmEventdb = {
    label: 'EventDB',
    name: 'om_eventdb',
    type: 'sendTo',
    defaultDirectives: 'Module      om_eventdb',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'DBname',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => !item.UDS,
            name: 'Host',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Password',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => !item.Host,
            name: 'UDS',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'UserName',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'BulkLoad',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            if: (item) => item.BulkLoad !== 'FALSE',
            name: 'LoadInterval',
            type: 'number',
            defaultValue: 20
        },
        {
            if: (item) => !item.UDS,
            name: 'Port',
            type: 'number',
            defaultValue: 3306
        },
        {
            if: (item) => item.BulkLoad !== 'FALSE',
            name: 'TempDir',
            type: 'string',
            defaultValue: '/tmp'
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
