import GENERAL_DIRECTIVES from '../generalDirectives';

export const ImMs365 = {
    label: 'Microsoft 365',
    name: 'im_ms365',
    type: 'collect',
    defaultDirectives: 'Module      im_ms365',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'ClientId',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'TenantId',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'TenantDomain',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'Secret',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertKeyFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'ActivityReports',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'DeviceManagement',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'EndDate',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'IdentityAndAccessReports',
            type: 'string',
            multiple: true,
            separator: ', ',
            defaultValue: []
        },
        {
            name: 'IdentityProtection',
            type: 'select',
            options: [
                'RiskDetection',
                'RiskyUser',
                'ServicePrincipalRiskDetections',
                'RiskyServicePrincipal'
            ],
            multiple: true,
            separator: ', ',
            defaultValue: []
        },
        {
            name: 'ManagementActivity',
            type: 'select',
            options: ['AzureActiveDirectory', 'Exchange', 'SharePoint'],
            multiple: true,
            separator: ', ',
            defaultValue: []
        },
        {
            name: 'MsGraph',
            type: 'block',
            defaultValue: {},
            requiredInConfigText: false,
            block: [
                {
                    name: 'AuthURL',
                    type: 'string',
                    defaultValue: ''
                },
                {
                    name: 'URL',
                    type: 'string',
                    defaultValue: ''
                },
                {
                    name: 'Version',
                    type: 'string',
                    defaultValue: ''
                }
            ]
        },
        {
            name: 'MsGraphBeta',
            type: 'block',
            defaultValue: {},
            block: [
                {
                    name: 'AuthURL',
                    type: 'string',
                    defaultValue: ''
                },
                {
                    name: 'URL',
                    type: 'string',
                    defaultValue: ''
                },
                {
                    name: 'Version',
                    type: 'string',
                    defaultValue: ''
                }
            ]
        },
        {
            name: 'Office365',
            type: 'block',
            defaultValue: {},
            block: [
                {
                    name: 'AuthURL',
                    type: 'string',
                    defaultValue: ''
                },
                {
                    name: 'URL',
                    type: 'string',
                    defaultValue: ''
                },
                {
                    name: 'Version',
                    type: 'string',
                    defaultValue: ''
                }
            ]
        },
        {
            name: 'Outlook365',
            type: 'block',
            defaultValue: {},
            block: [
                {
                    name: 'AuthURL',
                    type: 'string',
                    defaultValue: ''
                },
                {
                    name: 'URL',
                    type: 'string',
                    defaultValue: ''
                }
            ]
        },
        {
            name: 'PrivilegedIdentityManagement',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'QueryPeriod',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'ReadFromLast',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Reconnect',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'ReportingWebService',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'RetryPeriod',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SavePos',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'ServiceCommunications',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'StartDate',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertPattern',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'UseCNGCertificates',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
