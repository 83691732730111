import GENERAL_DIRECTIVES from '../generalDirectives';

export const ImTcp = {
    label: 'TCP Transport',
    type: 'collect',
    name: 'im_tcp',
    defaultDirectives: 'Module      im_tcp',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'ListenAddr',
            type: 'string',
            required: true,
            requiredInConfigText: true,
            value: '0.0.0.0:1514',
            defaultValue: '0.0.0.0:1514'
        },
        {
            name: 'AllowIP',
            type: 'string',
            multiple: true,
            defaultValue: []
        },
        {
            name: 'BlockIP',
            type: 'string',
            multiple: true,
            defaultValue: []
        },
        {
            name: 'ReusePort',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'ReuseAddr',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'ExclusiveAddrUse',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        ...GENERAL_DIRECTIVES
    ]
};
export default null;
