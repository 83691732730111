export const XmSyslog = {
    label: 'Syslog',
    type: 'extension',
    name: 'xm_syslog',
    module: 'xm_syslog',
    defaultDirectives: `Module      xm_syslog`,
    functions: [
        {
            name: 'syslog_facility_string'
        },
        {
            name: 'syslog_facility_value'
        },
        {
            name: 'syslog_severity_string'
        },
        {
            name: 'syslog_severity_value'
        },
        {
            name: 'parse_syslog'
        },
        {
            name: 'parse_syslog_bsd'
        },
        {
            name: 'parse_syslog_ietf'
        },
        {
            name: 'to_snare'
        },
        {
            name: 'to_syslog_bsd'
        },
        {
            name: 'to_syslog_ietf'
        },
        {
            name: 'to_syslog_snare'
        }
    ],
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'IETFTimestampInGMT',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'ReplaceLineBreaks',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SnareDelimiter',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SnareReplacement',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'UTCTimestamp',
            type: 'string',
            defaultValue: ''
        }
    ]
};

export default null;
