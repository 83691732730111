import GENERAL_DIRECTIVES from '../generalDirectives';

export const ImSsl = {
    label: 'TLS/SSL Transport',
    name: 'im_ssl',
    type: 'collect',
    defaultDirectives: 'Module      im_ssl',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'ListenAddr',
            type: 'string',
            required: true,
            requiredInConfigText: true,
            defaultValue: '0.0.0.0:6514',
            value: '0.0.0.0:6514'
        },
        {
            name: 'AllowIP',
            type: 'string',
            multiple: true,
            defaultValue: []
        },
        {
            name: 'BlockIP',
            type: 'string',
            multiple: true,
            defaultValue: []
        },
        {
            name: 'AllowExpired',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'AllowUntrusted',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'CADir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CAFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CAPattern',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CAThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.CADir && item.CAFile && item.CAThumbprint,
            name: 'SearchAllCertStores',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'CertFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertKeyFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertPattern',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'UseCNGCertificates',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'CertThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'KeyPass',
            type: 'string',
            defaultValue: ''
        },

        {
            name: 'CRLDir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CRLFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'DHFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'RequireCert',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'TLSConnectLog',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'SSLCipher',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SSLCiphersuites',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SSLCompression',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'SSLProtocol',
            type: 'select',
            options: ['SSLv2', 'SSLv3', 'TLSv1', 'TLSv1.1', 'TLSv1.2', 'TLSv1.3'],
            defaultValue: [],
            multiple: true,
            separator: ', '
        },
        ...GENERAL_DIRECTIVES
    ]
};
export default null;
