import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@nxlog/common-ui/components';
import CirclePlusIcon from '@nxlog/common-ui/dist/components/svgs/circle-plus';
import classNames from 'classnames';
import AgentNotifications from '../../components/agentNotifications';
import EnrollmentProgress from '../../components/enrollmentProgress';
import TableAgents from '../../components/tables/tableAgents';
import Loader from '../../components/common/loader';
import timeOut, { parseBool } from '../../utils/helpers/functions';
import { useCheckedRecords } from '../../utils/hooks';
import SelectedAgents from '../../components/selectedAgents';
import UnenrollConfirm from '../../components/modals/unenrollConfirmModal';
import AssignTemplateModal from '../../components/modals/assignTemplateModal';
import EditConfigModal from '../../components/modals/editConfigModal';
import ViewLogModal from '../../components/modals/viewLogModal';
import { handleAgentApiFilter } from '../../utils/helpers/tables';
import {
    selectPaginatedAgents,
    selectItemsPerPageValue,
    assignTemplateData,
    selectAgentsUpdated,
    resetFetchedPages,
    enrollMultipleAgentsUsingCustomFilter
} from '../../redux/reducers/agents';
import {
    getTemplatesData,
    selectTemplates,
    postTemplateData
} from '../../redux/reducers/templates';
import { createFilterString } from '../../utils/helpers/strings';
import useAgentTable from '../../utils/hooks/useAgentTable';
import { AGENT_SELECTOR, FILTER_BY_TYPE } from '../../utils/constants/ids';
import useCreateFilters from '../../utils/hooks/useCreateFilters';
import TutorialModal from '../../components/modals/tutorialModal';
import WelcomeScreen from '../../components/welcomeScreen';
import useFiltersFromURL from '../../utils/hooks/useFiltersFromURL';
import useEnrollmentAddress, { useRelayAddress } from '../../utils/hooks/useEnrollmentAddress';
import UnassignConfirm from '../../components/modals/unassignConfirmModal';
import useRepeatWhenVisible from '../../utils/hooks/useRepeatWhenVisible';
import MQLEditor from '../../components/mqlEditor';
import DisplayAppliedFilters from '../../components/displayAppliedFilters';
import './agents.scss';

function AgentsPage() {
    const airGapped = parseBool(
        window?.env?.REACT_APP_AIR_GAPPED ?? process.env.REACT_APP_AIR_GAPPED
    );
    const dispatch = useDispatch();
    const paginatedAgents = useSelector(selectPaginatedAgents);
    const itemsPerPageValue = useSelector(selectItemsPerPageValue);
    const agentsUpdated = useSelector(selectAgentsUpdated);
    const configNames = useSelector(selectTemplates);
    const [loading, setLoading] = useState(true);
    const [defaultTemplateCreated, setDefaultTemplateCreated] = useState(false);
    const [templateLoading, setTemplateLoading] = useState(true);
    const [showTutorial, setShowTutorial] = useState(false);

    const savedSearchTags = JSON.parse(localStorage.getItem('appliedAgentTags')) ?? [];
    const logData = useSelector((state) => state.agents.agentLog);
    const initialFilters = useFiltersFromURL();

    const addressString = useRelayAddress();
    const enrollmentAddress = useEnrollmentAddress();
    const [showConfirmDelete, setShowConfirmDelete] = useState(null);
    const [showConfirmUnassign, setShowConfirmUnassign] = useState(null);
    const [actionLoading, setActionLoading] = useState(false);

    const {
        agents,
        filteredAgentsCount,
        agentsCount,
        agentEverConnected,
        modalShow,
        logModalShow,
        currentPage,
        configData,
        currentRows,
        currentRowsAction,
        AGENT_TABLE_ROW_FUNCTIONS,
        AGENT_TABLE_ROW_FUNCTIONS_BY_FILTER,
        isAgentsTemplateModalVisible,
        currentAgentId,
        currentTemplate,
        appliedFilters,
        generalFilter,
        tags,
        addNewTag,
        processes,
        sortFields,
        addSortField,
        setGeneralFilter,
        resetAndFetchAgents,
        applyFilter,
        removeFilter,
        resetFilters,
        refetchCurrrentPage,
        refetchAgentsCount,
        refetchAgentEverConnected,
        setModalShow,
        setLogModalShow,
        setCurrentPage,
        setAgentsTemplateModalVisible,
        handleChangePage,
        strFilter
    } = useAgentTable(initialFilters);

    const {
        checkedRecords,
        handleRemoveAll,
        handleRemoveRecord,
        handleRemoveRecords,
        handleSetRecords
    } = useCheckedRecords(new Map(), AGENT_SELECTOR);

    const { filters, okAgentsCount, warningAgentsCount, errorAgentsCount, offlineAgentsCount } =
        useCreateFilters({ applyFilter, removeFilter, appliedFilters });

    const [isFilteredDataLoaded, setFilteredDataLoaded] = useState(false);

    const reloadData = () => {
        if (!agentEverConnected) {
            refetchAgentEverConnected();
        }
        refetchCurrrentPage();
        refetchAgentsCount();
    };

    useEffect(() => {
        if (agentsUpdated) {
            dispatch(resetFetchedPages([]));
            reloadData();
        }
    }, [agentsUpdated]);

    useRepeatWhenVisible(reloadData, 10000, [
        appliedFilters,
        itemsPerPageValue,
        currentPage,
        sortFields,
        agentEverConnected
    ]);

    useEffect(() => {
        dispatch(getTemplatesData({ fields: 'id,name,tags' }));
    }, []);

    const handleApplyOption = (value, row, usingFilter) => {
        if (usingFilter) {
            setActionLoading(true);

            return AGENT_TABLE_ROW_FUNCTIONS_BY_FILTER[value](row).finally(() => {
                setActionLoading(false);

                if (value !== 'assignTemplate') {
                    handleRemoveAll();
                }
            });
        }

        return AGENT_TABLE_ROW_FUNCTIONS[value](row);
    };

    const handleSelectOption = (value, row, usingFilter = false, count = 1) => {
        switch (value) {
            case 'delete':
                setShowConfirmDelete([count, value, row, usingFilter]);
                break;
            case 'unassignTemplate':
                setShowConfirmUnassign([count, value, row, usingFilter]);
                break;
            default:
                handleApplyOption(value, row, usingFilter);
        }
    };

    const handleCancelDelete = () => {
        setShowConfirmDelete(null);
    };

    const handleConfirmDelete = () => {
        const [, /* skip count var */ ...rest] = showConfirmDelete;

        handleApplyOption(...rest);
        handleCancelDelete();
    };

    const handleCancelUnassign = () => {
        setShowConfirmUnassign(null);
    };

    const handleConfirmUnassign = () => {
        const [, /* skip count var */ ...rest] = showConfirmUnassign;

        handleApplyOption(...rest);
        handleCancelUnassign();
    };

    const handleSaveAgentTemplate = (data) => {
        let action = () => null;
        const configId = data.value;

        switch (currentRowsAction) {
            case 'enrollwithtemplate':
                action = (filter) =>
                    dispatch(
                        enrollMultipleAgentsUsingCustomFilter({ filter, templateId: configId })
                    );
                break;
            default:
                action = (filter) => dispatch(assignTemplateData({ filter, id: configId }));
                break;
        }

        if (Array.isArray(currentRows)) {
            const agentIds = currentRows.map((row) => row.id);
            agentIds.forEach((agentId) => action(`id = ${agentId}`));
            setAgentsTemplateModalVisible(false);
        } else if ('filter' in currentRows) {
            action(currentRows.filter).finally(() => {
                setTimeout(() => {
                    setAgentsTemplateModalVisible(false);
                }, 100);
                handleRemoveAll();
            });
        }
    };

    const handleApllyFilter = (newFilter) => {
        setGeneralFilter('all');
        applyFilter(newFilter);
        resetAndFetchAgents(createFilterString([...appliedFilters, newFilter]), null, 'all');
    };

    const handleChangeItemsAmount = (itemsAmount) => {
        resetAndFetchAgents(createFilterString(appliedFilters), itemsAmount);
    };

    const handleSeeAllByType = (type) => {
        resetFilters([]);
        setGeneralFilter(type);
        resetAndFetchAgents(FILTER_BY_TYPE[type], null, type);
    };

    useEffect(() => {
        if (savedSearchTags) {
            handleAgentApiFilter(
                savedSearchTags,
                resetAndFetchAgents,
                createFilterString,
                appliedFilters
            );
        }
        if (savedSearchTags.length === tags.length) setFilteredDataLoaded(true);
    }, [savedSearchTags?.length !== tags?.length, appliedFilters]);

    const selectedAgentsData = useMemo(() => Array.from(checkedRecords.values()), [checkedRecords]);

    const handleRemoveItem = (id) => {
        if (selectedAgentsData.map((item) => item.id).includes('*')) {
            handleSetRecords([...selectedAgentsData, { id }]);
        } else {
            handleRemoveRecord(id);
        }
    };

    useEffect(() => {
        if (
            agents.length > 0 &&
            ![okAgentsCount, warningAgentsCount, errorAgentsCount, offlineAgentsCount].includes(
                null
            )
        ) {
            setLoading(false);
        }
    }, [agents, okAgentsCount, warningAgentsCount, errorAgentsCount, offlineAgentsCount]);

    timeOut(loading, setLoading, 1000);

    const hideTutorial = () => {
        setShowTutorial(false);
    };

    const openTutorial = () => {
        setShowTutorial(true);
    };

    useEffect(() => {
        if (airGapped) return;

        const defaultTpls = [
            {
                comment: '',
                content: `LogLevel    INFO\nLogFile     %MYLOGFILE%\n    \n<Extension admin>\n    Module      xm_admin\n    Host        ${enrollmentAddress}\n\n    SocketType  SSL\n    CAFile      %CERTDIR%/agent-ca.pem\n    CertFile    %CERTDIR%/agent-cert.pem\n    CertKeyFile %CERTDIR%/agent-key.pem\n    <ACL conf>\n        Directory   %CONFDIR%\n        AllowRead   TRUE\n        AllowWrite  TRUE\n    </ACL>\n\n    <ACL cert>\n        Directory   %CERTDIR%\n        AllowRead   TRUE\n        AllowWrite  TRUE\n    </ACL>\n</Extension>\n\n\n<Input nxlog>\n    Module im_internal\n</Input>\n\n<Output data_relay>\n    UseSSL          TRUE\n    CAFile          %CERTDIR%/agent-ca.pem\n    CertFile        %CERTDIR%/agent-cert.pem\n    CertKeyFile     %CERTDIR%/agent-key.pem\n    Module          om_batchcompress\n    Host            ${addressString}\n</Output>\n\n<Route to_data_relay>\n    Path            nxlog => data_relay\n</Route>\n`,
                name: 'Agent logs to Platform'
            },
            {
                comment: '',
                content: `LogLevel    INFO\nLogFile     %MYLOGFILE%\n    \n<Extension admin>\n    Module      xm_admin\n    Host        ${enrollmentAddress}\n\n    SocketType  SSL\n    CAFile      %CERTDIR%/agent-ca.pem\n    CertFile    %CERTDIR%/agent-cert.pem\n    CertKeyFile %CERTDIR%/agent-key.pem\n    <ACL conf>\n        Directory   %CONFDIR%\n        AllowRead   TRUE\n        AllowWrite  TRUE\n    </ACL>\n\n    <ACL cert>\n        Directory   %CERTDIR%\n        AllowRead   TRUE\n        AllowWrite  TRUE\n    </ACL>\n</Extension>\n\n\n<Extension json>\n\tModule xm_json\n</Extension>\n\n<Extension syslog>\n\tModule xm_syslog\n</Extension>\n\n<Input nxlog>\n\tModule im_internal\n\tExec to_json();\n</Input>\n\n<Input bc>\n  Module im_batchcompress\n  ListenAddr 0.0.0.0:2514\n  CAFile         %CERTDIR%/agent-ca.pem\n  CertFile       %CERTDIR%/agent-cert.pem\n  CertKeyFile    %CERTDIR%/agent-key.pem\n  Exec to_json();\n</Input>\n\n<Input tcp>\n  Module im_tcp\n  ListenAddr 0.0.0.0:1514\n  Exec syslog->parse_syslog();\n  Exec to_json();\n</Input>\n\n<Input ssl>\n  Module im_ssl\n  ListenAddr 0.0.0.0:3514\n  CAFile         %CERTDIR%/agent-ca.pem\n  CertFile       %CERTDIR%/agent-cert.pem\n  CertKeyFile    %CERTDIR%/agent-key.pem\n  Exec syslog->parse_syslog();\n  Exec to_json();\n</Input>\n\n<Input udp>\n  Module im_udp\n  ListenAddr 0.0.0.0:1514\n  Exec syslog->parse_syslog_bsd();\n  Exec to_json();\n</Input>\n\n<Output transport_relay>\n  Module          om_batchcompress\n  Host            ${addressString}\n  UseSSL         TRUE\n  CAFile         %CERTDIR%/agent-ca.pem\n  CertFile       %CERTDIR%/agent-cert.pem\n  CertKeyFile    %CERTDIR%/agent-key.pem\n  LogQueueSize 1073741824\n  PersistLogqueue TRUE\n</Output>\n\n<Route default>\n  Path ssl,tcp,udp,nxlog,bc => transport_relay\n</Route>\n`,
                name: 'Agentless Network Input Relay'
            }
        ];

        const checkIfDefaultTemplateExists = (templates) => {
            if (Array.isArray(templates)) {
                const names = templates.map((template) => template?.name);
                return defaultTpls.every((tpl) => names.includes(tpl.name));
            }
            return false;
        };

        if (
            !templateLoading &&
            !defaultTemplateCreated &&
            !checkIfDefaultTemplateExists(configNames)
        ) {
            const names = configNames.map((template) => template?.name);
            defaultTpls
                .filter((tpl) => !names.includes(tpl.name))
                .forEach((tpl) => dispatch(postTemplateData(tpl)));
            setDefaultTemplateCreated(true);
        }
    }, [templateLoading, airGapped]);

    useEffect(() => {
        if (Array.isArray(configNames)) {
            setTemplateLoading(false);
        }
    }, [configNames]);

    return (
        <>
            <div
                data-testid="agents-page"
                className={classNames(
                    'agents-page w-100',
                    !!selectedAgentsData.length && 'with-right-panel'
                )}
            >
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <EnrollmentProgress />
                        <section className="top-content">
                            {agents && (
                                <div className="agents-notifications">
                                    <AgentNotifications
                                        setCurrentPage={setCurrentPage}
                                        setTableData={handleSeeAllByType}
                                        amount={okAgentsCount}
                                        isActive={generalFilter === 'ok'}
                                        text="OK agents"
                                        type="ok"
                                    />
                                    <AgentNotifications
                                        setCurrentPage={setCurrentPage}
                                        setTableData={handleSeeAllByType}
                                        amount={errorAgentsCount}
                                        isActive={generalFilter === 'error'}
                                        text="Error agents"
                                        type="error"
                                    />
                                    <AgentNotifications
                                        setCurrentPage={setCurrentPage}
                                        setTableData={handleSeeAllByType}
                                        amount={warningAgentsCount}
                                        isActive={generalFilter === 'warning'}
                                        text="Warning agents"
                                        type="warning"
                                    />
                                    <AgentNotifications
                                        setCurrentPage={setCurrentPage}
                                        setTableData={handleSeeAllByType}
                                        amount={offlineAgentsCount}
                                        isActive={generalFilter === 'offline'}
                                        text="Offline agents"
                                        type="offline"
                                    />
                                    <AgentNotifications
                                        setCurrentPage={setCurrentPage}
                                        setTableData={handleSeeAllByType}
                                        amount={agentsCount}
                                        isActive={generalFilter === 'all'}
                                        text="All agents"
                                        type="all"
                                    />
                                </div>
                            )}
                        </section>
                        <div data-testid="agents-page-search" className="agent-search">
                            <MQLEditor onCreate={addNewTag} />
                            {agentEverConnected && (
                                <Button
                                    data-testid="agents-page-tutorial-open"
                                    className="add-new-config"
                                    icon={<CirclePlusIcon fill="#fff" />}
                                    onClick={openTutorial}
                                >
                                    Add new agent
                                </Button>
                            )}
                        </div>
                        <DisplayAppliedFilters
                            appliedFilters={appliedFilters}
                            removeFilter={removeFilter}
                        />
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {!isFilteredDataLoaded && <Loader />}{' '}
                        {agentEverConnected && (
                            <TableAgents
                                onChangeItemsAmount={handleChangeItemsAmount}
                                appliedFilters={appliedFilters}
                                applyFilter={handleApllyFilter}
                                removeFilter={removeFilter}
                                allDataAmount={filteredAgentsCount}
                                tableData={paginatedAgents}
                                filters={filters}
                                onSort={addSortField}
                                checkedRows={checkedRecords}
                                setCheckedRows={handleSetRecords}
                                currentPage={currentPage}
                                setCurrentPage={handleChangePage}
                                onSelectOption={handleSelectOption}
                                processes={processes}
                            />
                        )}
                        {!agentEverConnected && <WelcomeScreen />}
                    </>
                )}
            </div>
            {!!selectedAgentsData.length && (
                <SelectedAgents
                    strFilter={strFilter}
                    data={selectedAgentsData}
                    onRemoveAll={handleRemoveAll}
                    onRemoveAgent={handleRemoveItem}
                    onClearSelected={handleRemoveRecords}
                    onSelectOption={handleSelectOption}
                    loading={actionLoading}
                />
            )}
            <UnenrollConfirm
                show={Boolean(showConfirmDelete)}
                count={showConfirmDelete ? showConfirmDelete[0] : 0}
                onCancel={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />
            <UnassignConfirm
                show={Boolean(showConfirmUnassign)}
                count={showConfirmUnassign ? showConfirmUnassign[0] : 0}
                onCancel={handleCancelUnassign}
                onConfirm={handleConfirmUnassign}
            />
            <AssignTemplateModal
                data={configNames}
                isShown={isAgentsTemplateModalVisible}
                onClose={AGENT_TABLE_ROW_FUNCTIONS.assignTemplate}
                onSave={handleSaveAgentTemplate}
                action={currentRowsAction}
            />
            <EditConfigModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                configData={configData}
                currentAgentId={currentAgentId}
                templateName={currentTemplate}
            />
            <ViewLogModal
                show={logModalShow}
                onHide={() => setLogModalShow(false)}
                logData={logData}
                currentAgentId={currentAgentId}
                classname="view-log"
            />
            <TutorialModal show={showTutorial} onHide={hideTutorial} />
        </>
    );
}

export default AgentsPage;
