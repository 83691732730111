import GENERAL_DIRECTIVES from '../generalDirectives';

export const ImMseventlog = {
    label: 'Windows XP/2000/2003 Event Logging',
    type: 'collect',
    name: 'im_mseventlog',
    defaultDirectives: `Module      im_mseventlog`,
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'ReadFromLast',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'SavePos',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'Sources',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'UTF8',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
