import GENERAL_DIRECTIVES from '../generalDirectives';

export const OmFile = {
    label: 'File',
    type: 'sendTo',
    name: 'om_file',
    defaultDirectives: 'Module      om_file',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'File',
            type: 'string',
            quoting: true,
            required: true,
            defaultValue: ''
        },
        {
            name: 'CreateDir',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'Sync',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'Truncate',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        ...GENERAL_DIRECTIVES
    ]
};
export default null;
