import GENERAL_DIRECTIVES from '../generalDirectives';

export const OmRedis = {
    label: 'Redis',
    name: 'om_redis',
    type: 'sendTo',
    defaultDirectives: 'Module      om_redis',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Host',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            if: (item) => ['PUBLISH'].includes(item.Command),
            name: 'Channel',
            type: 'string',
            defaultValue: 'nxlog'
        },
        {
            name: 'Command',
            type: 'select',
            options: ['LPUSH', 'RPUSH', 'LPUSHX', 'RPUSHX', 'PUBLISH'],
            defaultValue: 'RPUSH'
        },
        {
            if: (item) => ['PUBLISH'].includes(item.Command),
            name: 'Key',
            type: 'string',
            defaultValue: 'nxlog'
        },
        {
            name: 'LocalPort',
            type: 'number',
            defaultValue: ''
        },
        {
            name: 'OutputType',
            type: 'select',
            options: ['Binary', 'Dgram', 'LineBased', 'LineBased_CRLF', 'LineBased_LF'],
            defaultValue: 'Dgram'
        },
        {
            name: 'Port',
            type: 'number',
            defaultValue: 6379
        },
        {
            name: 'Reconnect',
            type: 'string',
            defaultValue: ''
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
