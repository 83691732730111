export const XmJson = {
    label: 'JSON',
    type: 'extension',
    name: 'xm_json',
    module: 'xm_json',
    defaultDirectives: `Module      xm_json`,
    functions: [
        {
            name: 'extract_json'
        },
        {
            name: 'parse_json'
        },
        {
            name: 'to_json'
        }
    ],
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'DateFormat',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'DetectNestedJSON',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Flatten',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'UnFlatten',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'ForceUTF8',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'IncludeHiddenFields',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'ParseDate',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'PrettyPrint',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        }
    ]
};

export default null;
