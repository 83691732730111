import GENERAL_DIRECTIVES from '../generalDirectives';

export const OmElasticsearch = {
    label: 'Elasticsearch',
    name: 'om_elasticsearch',
    type: 'sendTo',
    defaultDirectives: 'Module      om_elasticsearch',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'URL',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'AddHeader',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Index',
            type: 'string',
            defaultValue: 'nxlog'
        },
        {
            name: 'IndexType',
            type: 'string',
            defaultValue: '_doc'
        },
        {
            name: 'ID',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPBasicAuthUser',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPBasicAuthPassword',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSAllowExpired',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'HTTPSAllowUntrusted',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'HTTPSCADir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCAFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCAThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSSearchAllCertStores',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'HTTPSCertFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCertKeyFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCertThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCRLDir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCRLFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSDHFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSKeyPass',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSSSLCipher',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSSSLCiphersuites',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSSSLCompression',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'HTTPSSSLProtocol',
            type: 'select',
            options: ['SSLv2', 'SSLv3', 'TLSv1', 'TLSv1.1', 'TLSv1.2', 'TLSv1.3'],
            defaultValue: [],
            multiple: true,
            separator: ', '
        },
        {
            name: 'LocalPort',
            type: 'number',
            defaultValue: ''
        },
        {
            name: 'Proxy',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Reconnect',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'NoDefaultIndexType',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'SNI',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'OnError',
            type: 'block',
            defaultValue: {},
            block: [
                {
                    name: 'Exec',
                    type: 'multiline-string-block',
                    defaultValue: ''
                },
                {
                    name: 'RetryLimit',
                    type: 'number',
                    defaultValue: 100
                }
            ]
        },
        {
            name: 'UseCNGCertificates',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
