import GENERAL_DIRECTIVES from '../generalDirectives';

export const OmTcp = {
    label: 'TCP Transport',
    type: 'sendTo',
    name: 'om_tcp',
    defaultDirectives: 'Module      om_tcp',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'AllowIP',
            type: 'string',
            multiple: true,
            defaultValue: []
        },
        {
            name: 'Host',
            type: 'string',
            required: true,
            defaultValue: []
        },
        {
            name: 'ListenAddr',
            type: 'string',
            defaultValue: [],
            multiple: true
        },
        {
            name: 'Port',
            type: 'number',
            defaultValue: 514
        },
        {
            name: 'QueueInListenMode',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'LocalPort',
            type: 'number',
            defaultValue: ''
        },
        {
            name: 'OutputType',
            type: 'select',
            options: ['Binary', 'Dgram', 'LineBased', 'LineBased_CRLF', 'LineBased_LF'],
            defaultValue: 'LineBased_LF'
        },
        {
            name: 'Reconnect',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'TCPNoDelay',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        ...GENERAL_DIRECTIVES
    ]
};
export default null;
