export const XmLeef = {
    label: 'LEEF',
    type: 'extension',
    name: 'xm_leef',
    module: 'xm_leef',
    defaultDirectives: `Module      xm_leef`,
    functions: [
        {
            name: 'to_leef'
        },
        {
            name: 'parse_leef'
        }
    ],
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'AddSyslogHeader',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'IncludeHiddenFields',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'LEEFHeader',
            type: 'string',
            defaultValue: ''
        }
    ]
};

export default null;
