import GENERAL_DIRECTIVES from '../generalDirectives';

export const OmPipe = {
    label: 'Named Pipe',
    name: 'om_pipe',
    type: 'sendTo',
    defaultDirectives: 'Module      om_pipe',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Pipe',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'Group',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Perms',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'User',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'OutputType',
            type: 'select',
            options: ['Binary', 'Dgram', 'LineBased', 'LineBased_CRLF', 'LineBased_LF'],
            defaultValue: 'LineBased'
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
