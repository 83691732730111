import GENERAL_DIRECTIVES from '../generalDirectives';

export const ImRegmon = {
    label: 'Windows Registry Monitoring',
    name: 'im_regmon',
    type: 'collect',
    defaultDirectives: 'Module      im_regmon',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'RegValue',
            type: 'string',
            defaultValue: [{ value: '' }],
            multiple: true,
            required: true
        },
        {
            name: '64BitView',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'Digest',
            type: 'select',
            options: [
                'md2',
                'md5',
                'mdc2',
                'rmd160',
                'sha',
                'sha1',
                'sha224',
                'sha256',
                'sha384',
                'sha512'
            ],
            defaultValue: 'sha1'
        },
        {
            name: 'Exclude',
            type: 'string',
            defaultValue: [],
            multiple: true
        },
        {
            name: 'Recursive',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'ScanInterval',
            type: 'number',
            defaultValue: 86400
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
