import GENERAL_DIRECTIVES from '../generalDirectives';

export const OmDbi = {
    label: 'DBI',
    type: 'sendTo',
    name: 'om_dbi',
    defaultDirectives: 'Module      om_dbi',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Driver',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'SQL',
            type: 'multiline-string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Option',
            type: 'string',
            multiple: true,
            defaultValue: [{ value: '' }]
        },
        ...GENERAL_DIRECTIVES
    ]
};
export default null;
