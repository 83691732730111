import GENERAL_DIRECTIVES from '../generalDirectives';

export const OmZmq = {
    label: 'ZeroMQ',
    name: 'om_zmq',
    type: 'sendTo',
    defaultDirectives: 'Module      om_zmq',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Address',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'ConnectionType',
            type: 'select',
            options: ['', 'TCP', 'PGM', 'EPGM'],
            defaultValue: '',
            required: true
        },
        {
            name: 'Port',
            type: 'number',
            defaultValue: ''
        },
        {
            name: 'SocketType',
            type: 'select',
            options: ['', 'REQ', 'ROUTER', 'PUB', 'XPUB', 'PUSH'],
            defaultValue: '',
            required: true
        },
        {
            name: 'Interface',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Listen',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'OutputType',
            type: 'select',
            options: ['Binary', 'Dgram', 'LineBased', 'LineBased_CRLF', 'LineBased_LF'],
            defaultValue: 'Dgram'
        },
        {
            name: 'SockOpt',
            type: 'string',
            defaultValue: [],
            multiple: true
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
