import GENERAL_DIRECTIVES from '../generalDirectives';

export const OmWebhdfs = {
    label: 'WebHDFS',
    name: 'om_webhdfs',
    type: 'sendTo',
    defaultDirectives: 'Module      om_webhdfs',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'File',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'URL',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'FlushInterval',
            type: 'number',
            defaultValue: 5
        },
        {
            name: 'FlushLimit',
            type: 'number',
            defaultValue: 500
        },
        {
            name: 'HTTPSAllowExpired',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'HTTPSAllowUntrusted',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'HTTPSCADir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCAFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCAThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSSearchAllCertStores',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCertFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCertKeyFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCertThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCRLDir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCRLFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSDHFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSKeyPass',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSSSLCipher',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSSSLCiphersuites',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSSSLCompression',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'HTTPSSSLProtocol',
            type: 'select',
            options: ['SSLv2', 'SSLv3', 'TLSv1', 'TLSv1.1', 'TLSv1.2', 'TLSv1.3'],
            multiple: true,
            separator: ', ',
            defaultValue: []
        },
        {
            name: 'QueryParam',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Reconnect',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'UseCNGCertificates',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
