import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ItemDisplay, ItemReceiver } from '../dragAndDropItems';
import { DragAndDropContext } from '../utils/helper';
import { getModuleName, moduleIsCompleted } from '../utils/generateConfigCode';

function RouteEditorModuleItem({
    mdl,
    type,
    onModule,
    onAction,
    update,
    isSolutionPack,
    hideActions,
    onDelete,
    isUsed
}) {
    const { openConfig } = useContext(DragAndDropContext);

    const handleModuleAction = (action) => {
        if (action === 'delete' && !isSolutionPack) {
            mdl.module = null;
            update();
            if (onDelete) onDelete();
        } else {
            openConfig(type, mdl, isSolutionPack);
        }
    };

    const handleActionAction = (item) => (action) => {
        if (action === 'delete' && !isSolutionPack) {
            mdl.actions = mdl.actions.filter((elm) => elm !== item);
            update();
        } else {
            openConfig('action', mdl, isSolutionPack);
        }
    };

    const actionsCount = mdl.actions.length;
    const completed = mdl.module ? moduleIsCompleted(mdl.module) : true;

    const defaultLabel =
        { collect: 'Drag from the ‘Collect From’ list', sendTo: 'Drag from the ‘Send to’ list' }[
            type
        ] ?? '+ Drag module';

    if (isSolutionPack && !mdl.module) return null;
    return (
        <div className="route-editor-module-item" data-testid="route-editor-module-item">
            {mdl.module ? (
                <ItemDisplay
                    flag={mdl.module.sp || isSolutionPack ? 'SP' : null}
                    uncompleted={!completed}
                    testid={mdl.key}
                    className="dnd-drop-item-module"
                    onClick={handleModuleAction}
                    isSolutionPack={isSolutionPack}
                    deleteButtonDisabled={isUsed}
                >
                    {`${mdl.module.label} (${getModuleName(mdl)})`}
                </ItemDisplay>
            ) : (
                <ItemReceiver
                    type={type}
                    testid={mdl.key}
                    className="dnd-drop-item-module"
                    label={defaultLabel}
                    onDrop={isSolutionPack ? () => {} : onModule}
                    helperText={`Drag an item from the ${
                        { collect: 'Collect From', sendTo: 'Send to' }[type]
                    } menu in the sidebar, and drop it here to add a snippet to your configuration.`}
                />
            )}
            {mdl.module || mdl.actions.length ? (
                <div className="actionlist">
                    {mdl.actions.slice(0, 3).map((action, i) => (
                        <ItemDisplay
                            onClick={handleActionAction(action)}
                            key={action.label}
                            testid={`${mdl.key}-action-${i}`}
                            className="dnd-drop-item-action"
                            isSolutionPack={isSolutionPack}
                        >
                            {action.label}
                        </ItemDisplay>
                    ))}
                    {isSolutionPack || hideActions ? null : (
                        <ItemReceiver
                            testid={`${mdl.key}-action`}
                            hiddenReceiver={actionsCount > 3}
                            className="dnd-drop-item-action drop-new-action"
                            label={actionsCount > 1 ? '+' : '+ Drag from Process'}
                            type="action"
                            onDrop={isSolutionPack ? () => {} : onAction}
                            helperText="Drag an item from the Process menu in the sidebar, and drop it here to add a snippet to your configuration."
                        >
                            {actionsCount > 3 ? (
                                <RouteEditorModuleItemTooltip
                                    content={mdl.actions.slice(3).map((action, i) => (
                                        <ItemDisplay
                                            onClick={handleActionAction(action)}
                                            key={action.label}
                                            testid={`${mdl.key}-action-${i}`}
                                            className="TooltipAction"
                                            isSolutionPack={isSolutionPack}
                                        >
                                            {action.label}
                                        </ItemDisplay>
                                    ))}
                                >
                                    <div className="other-actions">+ {actionsCount - 3}</div>
                                </RouteEditorModuleItemTooltip>
                            ) : null}
                        </ItemReceiver>
                    )}
                </div>
            ) : null}
        </div>
    );
}

function RouteEditorModuleItemTooltip({ children, content }) {
    return (
        <OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom"
            overlay={<Tooltip className="TooltipActions">{content}</Tooltip>}
        >
            {children}
        </OverlayTrigger>
    );
}

RouteEditorModuleItemTooltip.defaultProps = {
    content: null,
    children: null
};

RouteEditorModuleItemTooltip.propTypes = {
    content: PropTypes.node,
    children: PropTypes.node
};

RouteEditorModuleItem.defaultProps = {
    type: '',
    mdl: { key: null, module: null, actions: [] },
    onModule: null,
    onAction: null,
    update: null,
    isSolutionPack: false,
    hideActions: false,
    onDelete: null,
    isUsed: false
};

RouteEditorModuleItem.propTypes = {
    type: PropTypes.string,
    mdl: PropTypes.shape({
        key: PropTypes.string,
        module: PropTypes.shape(),
        actions: PropTypes.arrayOf(PropTypes.shape())
    }),
    onModule: PropTypes.func,
    onAction: PropTypes.func,
    update: PropTypes.func,
    isSolutionPack: PropTypes.bool,
    hideActions: PropTypes.bool,
    onDelete: PropTypes.func,
    isUsed: PropTypes.bool
};

export default RouteEditorModuleItem;
