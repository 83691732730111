/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import propTypes from 'prop-types';
import Button from '@nxlog/common-ui/dist/components/button';
import Form from '@nxlog/common-ui/dist/components/form';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CircleX from '@nxlog/common-ui/dist/components/svgs/circle-x';
import { DragAndDropContext, structuredClone } from '../utils/helper';
import { ConfigEditorList } from '../configEditorList';
import { ItemEmetter, ItemReceiver } from '../dragAndDropItems';
import { IconDelete, RightArrow } from '../utils/Icons';
import { CodeEditor } from '../configTextEditor';
import { actions } from '../modules';
import { autoCompleteKeys } from '../utils/autoCompleteKeys';

function ConfigModuleActions() {
    const [item, setItem] = useState(null);
    const [q, setQ] = useState('');
    const { configItem, closeConfig, update } = useContext(DragAndDropContext);

    useEffect(() => {
        setItem(
            configItem && configItem.elm && configItem.type === 'action'
                ? structuredClone(configItem.elm)
                : null
        );
    }, [configItem]);

    const onSubmit = (data) => {
        if (configItem && configItem.elm) {
            Object.assign(configItem.elm, { ...data });
            update();
            closeConfig();
        }
    };

    const handleDrop = (d1, data) => {
        if (data?.callback) data?.callback();
    };

    return (
        <Offcanvas
            show={!!item}
            onHide={closeConfig}
            placement="end"
            className="configeditor-sidebar"
        >
            <Offcanvas.Body className="configeditor-sidebar-body">
                <div className="configeditor-form-container configeditor-form-actions">
                    <div className="configeditor-sidebar-header">
                        <Offcanvas.Title>Process</Offcanvas.Title>
                    </div>
                    <div className="scroll">
                        <div className="input-form-container searchinput">
                            <input
                                value={q}
                                onChange={(e) => setQ(e.target.value)}
                                data-testid="search"
                                className="input-form"
                                placeholder="Search..."
                            />
                        </div>
                        <ConfigEditorList list={actions} q={q} />
                        <ItemReceiver
                            type="delete-action"
                            className="delete-action"
                            label={
                                <div>
                                    <IconDelete className="delete-action-icon" /> Remove process
                                </div>
                            }
                            onDrop={handleDrop}
                            displayOnlyOnDragging
                            testid="delete-action"
                        />
                    </div>
                </div>
                <Form onSubmit={onSubmit} FormProvider={FormProvider} defaultValues={{ ...item }}>
                    <div className="configeditor-form-container">
                        <div className="configeditor-sidebar-header">
                            <Offcanvas.Title>{item?.module?.label}</Offcanvas.Title>
                            <Button
                                icon={<CircleX outlined fill="#072341" />}
                                onClick={closeConfig}
                                data-testid="configeditor-close-btn"
                            />
                        </div>
                        <div className="scroll">
                            <div className="configeditor-form" data-testid="form body for actions">
                                {item?.actions ? (
                                    <ActionsList actionList={item.actions} isTopLevel />
                                ) : null}
                            </div>
                        </div>
                        <div className="configeditor-sidebar-actions">
                            <Button ghostBtn onClick={closeConfig}>
                                Cancel
                            </Button>
                            <Button type="submit" data-testid="configeditor-save-btn">
                                Save and Add
                            </Button>
                        </div>
                    </div>
                </Form>
                <datalist id="fields">
                    {autoCompleteKeys.map((key) => (
                        <option key={key}>{key}</option>
                    ))}
                </datalist>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

function ActionsList({ actionList, isTopLevel }) {
    const { update, addNewAction } = useContext(DragAndDropContext);

    const handleDrop = (data, refAction) => {
        if (data.type === 'action') {
            addNewAction(actionList, data);
        } else if (data.type === 'delete-action') {
            if (refAction?.callback) refAction?.callback();
            addNewAction(actionList, data.action);
        }
    };

    const handleDeleteAction = (i) => () => {
        actionList.splice(i, 1);
        update();
    };

    const handleMoveAction = (i) => (data, refAction) => {
        if (data.type === 'delete-action') {
            if (refAction?.callback) refAction?.callback();
            actionList.splice(i, 0, data.action);
            update();
        } else if (data.type === 'action') {
            addNewAction(actionList, data, i);
        }
    };

    return (
        <div className="actions-list" key={actionList.length}>
            {actionList.map((action, i) => (
                <ActionsItem
                    action={action}
                    key={action.key}
                    onDelete={handleDeleteAction(i)}
                    onMoveAction={handleMoveAction(i)}
                />
            ))}
            {isTopLevel ? (
                <ItemReceiver
                    type="action,delete-action"
                    label="+ Drag action"
                    onDrop={handleDrop}
                    testid="push-action"
                />
            ) : null}
        </div>
    );
}

ActionsList.defaultProps = {
    isTopLevel: false,
    actionList: []
};

ActionsList.propTypes = {
    isTopLevel: propTypes.bool,
    actionList: propTypes.arrayOf(
        propTypes.shape({
            label: propTypes.string
        })
    )
};

function ActionsItem({ action, onDelete, onMoveAction }) {
    const { bind, addNewAction } = useContext(DragAndDropContext);

    const handleDrop = (list) => (data, refAction) => {
        if (data.type === 'action') {
            addNewAction(list, data);
        } else if (data.type === 'delete-action') {
            if (refAction?.callback) refAction?.callback();
            addNewAction(list, data.action);
        }
    };

    const MoveAction = (
        <ItemReceiver
            className="MoveActionItemReceiver"
            type="action,delete-action"
            onDrop={onMoveAction}
            displayOnlyOnDragging={false}
            hiddenReceiver
            testid={`before-action:${action.name}`}
        >
            <RightArrow />
            <div className="MoveActionItemReceiverLine" />
            <RightArrow />
        </ItemReceiver>
    );

    if (action.label.includes('If-Else Condition')) {
        return (
            <ItemEmetter
                layout="delete-action"
                data={{ type: 'delete-action', callback: onDelete, action }}
                testid={`action:${action.name}`}
            >
                {MoveAction}
                <div>
                    <div className="IfCondition">
                        <div className="IfCondition-if">
                            <span>
                                <span className="IfCondition-flag">If</span>
                            </span>
                            <div className="action-form">
                                <input
                                    type="text"
                                    {...bind(action, 'condition_field', { list: 'fields' })}
                                    data-testid="if:field"
                                />
                                <select {...bind(action, 'condition_op')} data-testid="if:op">
                                    <option value="==">==</option>
                                    <option value="!=">!=</option>
                                    <option value="=~">=~</option>
                                    <option value="!~">!~</option>
                                    <option value="<">{'<'}</option>
                                    <option value="<=">{'<='}</option>
                                    <option value=">">{'>'}</option>
                                    <option value=">=">{'>='}</option>
                                    <option value="IN">IN</option>
                                    <option value="NOT IN">NOT IN</option>
                                    <option value="exists">is defined/exists</option>
                                </select>
                                {action.condition_op === 'exists' ? null : (
                                    <input
                                        type="text"
                                        {...bind(action, 'condition_value', { list: 'fields' })}
                                        data-testid="if:value"
                                    />
                                )}
                            </div>
                        </div>
                        <ItemReceiver
                            hiddenReceiver
                            className="IfCondition-then"
                            type="action,delete-action"
                            onDrop={handleDrop(action.then)}
                            testid="if:then"
                        >
                            <span>
                                <span className="IfCondition-flag">Then</span>
                            </span>
                            <div>
                                <ActionsList actionList={action.then} />
                            </div>
                        </ItemReceiver>
                        <ItemReceiver
                            hiddenReceiver
                            className="IfCondition-else"
                            type="action,delete-action"
                            onDrop={handleDrop(action.else)}
                            testid="if:else"
                        >
                            <span>
                                <span className="IfCondition-flag">Else</span>
                            </span>
                            <div>
                                <ActionsList actionList={action.else} />
                            </div>
                        </ItemReceiver>
                    </div>
                </div>
            </ItemEmetter>
        );
    }

    if (action.label === 'Add Field') {
        return (
            <ItemEmetter
                className="action-item"
                layout="delete-action"
                data={{ type: 'delete-action', callback: onDelete, action }}
                testid={`action:${action.name}`}
            >
                {MoveAction}
                <div className="action-form">
                    <input
                        type="text"
                        {...bind(action, 'field', { list: 'fields' })}
                        data-testid="addfield:field"
                    />
                    <span>=</span>
                    <input
                        type="text"
                        {...bind(action, 'value', { list: 'fields' })}
                        data-testid="addfield:value"
                    />
                </div>
            </ItemEmetter>
        );
    }

    if (action.label === 'Exec Statement') {
        return (
            <ItemEmetter
                className="action-item"
                layout="delete-action"
                data={{ type: 'delete-action', callback: onDelete, action }}
                testid={`action:${action.name}`}
            >
                {MoveAction}
                <div className="action-form">
                    <CodeEditor {...bind(action, 'code')} testid="exec:code" />
                </div>
            </ItemEmetter>
        );
    }

    if (action.label === 'Log') {
        return (
            <ItemEmetter
                className="action-item"
                layout="delete-action"
                data={{ type: 'delete-action', callback: onDelete, action }}
                testid={`action:${action.name}`}
            >
                {MoveAction}
                <div className="action-form">
                    <select {...bind(action, 'logtype')} data-testid="log:logtype">
                        <option value="log_info">INFO</option>
                        <option value="log_debug">DEBUG</option>
                        <option value="log_warning">WARNING</option>
                        <option value="log_error">ERROR</option>
                    </select>
                    <input
                        type="text"
                        {...bind(action, 'value', { list: 'fields' })}
                        data-testid="log:value"
                    />
                </div>
            </ItemEmetter>
        );
    }

    return (
        <ItemEmetter
            className="action-item"
            layout="delete-action"
            data={{ type: 'delete-action', callback: onDelete, action }}
            testid={`action:${action.name}`}
        >
            {MoveAction}
            {action.label}
        </ItemEmetter>
    );
}

ActionsItem.defaultProps = {
    onDelete: null,
    onMoveAction: null,
    action: []
};

ActionsItem.propTypes = {
    onDelete: propTypes.func,
    onMoveAction: propTypes.func,
    action: propTypes.shape({
        label: propTypes.string,
        name: propTypes.string,
        condition_field: propTypes.string,
        condition_op: propTypes.string,
        condition_value: propTypes.string,
        then: propTypes.arrayOf(propTypes.shape({})),
        else: propTypes.arrayOf(propTypes.shape({}))
    })
};

export default ConfigModuleActions;
