import GENERAL_DIRECTIVES from '../generalDirectives';

export const OmUdp = {
    label: 'UDP Transport',
    type: 'sendTo',
    name: 'om_udp',
    defaultDirectives: 'Module      om_udp',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Host',
            type: 'string',
            required: true,
            multiple: true,
            defaultValue: []
        },
        {
            name: 'LocalPort',
            type: 'number',
            defaultValue: ''
        },
        {
            name: 'OutputType',
            type: 'select',
            options: ['Binary', 'Dgram', 'LineBased', 'LineBased_CRLF', 'LineBased_LF'],
            defaultValue: 'Dgram'
        },
        {
            name: 'Reconnect',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SockBufSize',
            type: 'string',
            defaultValue: ''
        },
        ...GENERAL_DIRECTIVES
    ]
};
export default null;
