import GENERAL_DIRECTIVES from '../generalDirectives';

export const ImUds = {
    label: 'Unix Domain Sockets',
    name: 'im_uds',
    type: 'collect',
    defaultDirectives: 'Module      im_uds',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'UDS',
            type: 'string',
            required: true,
            requiredInConfigText: true,
            value: '/dev/log',
            defaultValue: '/dev/log'
        },
        {
            name: 'CreateDir',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'Group',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Perms',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'UDSType',
            type: 'select',
            options: ['dgram', 'stream'],
            defaultValue: 'dgram'
        },
        ...GENERAL_DIRECTIVES
    ]
};
export default null;
