const AgentConnectionTypeOptions = [
    'Connect to agent manager',
    'Listen (accept agent manager connection)'
];
export const XmAdmin = {
    label: 'Remote Agent Management',
    type: 'extension',
    name: 'xm_admin',
    module: 'xm_admin',
    defaultDirectives: `Module      xm_admin`,
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'AgentConnectionType',
            label: 'Agent connection type',
            type: 'select',
            options: AgentConnectionTypeOptions,
            defaultValue: '',
            required: true,
            isNotDirective: true,
            setValueAs: (value, data) => {
                if (data && value === AgentConnectionTypeOptions[0]) {
                    data.ListenAddr = '';
                }
                if (data && value === '') {
                    return data.ListenAddr
                        ? AgentConnectionTypeOptions[1]
                        : AgentConnectionTypeOptions[0];
                }
                return value;
            }
        },
        {
            name: 'Host',
            type: 'host-port',
            defaultValue: [{ host: '', port: '' }],
            multiple: true,
            required: (data) => data?.AgentConnectionType === AgentConnectionTypeOptions[0],
            if: (data) => data?.AgentConnectionType === AgentConnectionTypeOptions[0]
        },
        {
            name: 'ListenAddr',
            type: 'string',
            required: (data) => data?.AgentConnectionType === AgentConnectionTypeOptions[1],
            if: (data) => data?.AgentConnectionType === AgentConnectionTypeOptions[1],
            defaultValue: ''
        },
        {
            name: 'SocketType',
            type: 'select',
            options: ['SSL', 'TCP'],
            defaultValue: 'TCP'
        },
        {
            name: 'CADir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CAFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CAThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertKeyFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CRLDir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CRLFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'DHFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'KeyPass',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SearchAllCertStores',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'AllowExpired',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'AllowUntrusted',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'RequireCert',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'SSLCipher',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SSLCiphersuites',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SSLCompression',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'SSLProtocol',
            type: 'select',
            options: ['SSLv2', 'SSLv3', 'TLSv1', 'TLSv1.1', 'TLSv1.2', 'TLSv1.3'],
            defaultValue: [],
            multiple: true,
            separator: ', '
        },
        {
            name: 'CAPattern',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertPattern',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'UseCNGCertificates',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'ACL',
            type: 'block',
            defaultValue: [],
            multiple: true,
            emptyByDefault: true,
            block: [
                {
                    name: 'Name',
                    type: 'string',
                    required: true,
                    defaultValue: '',
                    isName: true
                },
                {
                    name: 'Directory',
                    type: 'string',
                    required: true,
                    defaultValue: ''
                },
                {
                    name: 'AllowRead',
                    type: 'select',
                    options: ['', 'TRUE', 'FALSE'],
                    defaultValue: ''
                },
                {
                    name: 'AllowWrite',
                    type: 'select',
                    options: ['', 'TRUE', 'FALSE'],
                    defaultValue: ''
                }
            ]
        },
        {
            name: 'AllowIP',
            type: 'string',
            multiple: true,
            defaultValue: []
        },
        {
            name: 'BlockIP',
            type: 'string',
            multiple: true,
            defaultValue: []
        },
        {
            name: 'ExclusiveAddrUse',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'ReuseAddr',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'ReusePort',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'DataTimeout',
            type: 'number',
            defaultValue: 300
        },
        {
            name: 'Labels',
            type: 'key-value',
            defaultValue: [],
            multiple: true
        },
        {
            if: (data) => !!data?.Host,
            name: 'Reconnect',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'ReversionTimeout',
            type: 'number',
            defaultValue: 300
        }
    ]
};

export default null;
