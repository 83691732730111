import GENERAL_DIRECTIVES from '../generalDirectives';

export const ImGooglepubsub = {
    label: 'Google Cloud Pub/Sub',
    name: 'im_googlepubsub',
    type: 'collect',
    defaultDirectives: 'Module      im_googlepubsub',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'CredentialsFile',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'Subscription',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'Acknowledge',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'Connections',
            type: 'number',
            defaultValue: 1
        },
        {
            name: 'GooglePubsubBatchSize',
            type: 'string',
            defaultValue: '65KB'
        },
        {
            name: 'PollInterval',
            type: 'number',
            defaultValue: 20
        },
        {
            name: 'Reconnect',
            type: 'number',
            defaultValue: ''
        },
        {
            name: 'ReadFromLast',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'SavePos',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'URL',
            type: 'string',
            defaultValue: ''
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
