import GENERAL_DIRECTIVES from '../generalDirectives';

export const ImCheckpoint = {
    label: 'Check Point OPSEC LEA',
    name: 'im_checkpoint',
    type: 'collect',
    defaultDirectives: 'Module      im_checkpoint',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Command',
            type: 'string',
            defaultValue: '/opt/nxlog/bin/nx-im-checkpoint'
        },
        {
            name: 'LEAConfigFile',
            type: 'string',
            defaultValue: '/opt/nxlog/etc/lea.conf'
        },
        {
            name: 'LogFile',
            type: 'string',
            defaultValue: 'fw.log'
        },
        {
            name: 'ReadFromLast',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'Restart',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'SavePos',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
