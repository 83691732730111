import GENERAL_DIRECTIVES from '../generalDirectives';

export const ImAcct = {
    label: 'Process Accounting',
    name: 'im_acct',
    type: 'collect',
    defaultDirectives: 'Module      im_acct',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'AcctOff',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'AcctOn',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'File',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'FileSizeLimit',
            type: 'string',
            defaultValue: '1M'
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
