import GENERAL_DIRECTIVES from '../generalDirectives';

export const OmJava = {
    label: 'Java Language',
    name: 'om_java',
    type: 'sendTo',
    defaultDirectives: 'Module      om_java',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'ClassPath',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'Run',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'VMOption',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'VMOptions',
            type: 'multiline-string-block',
            defaultValue: ''
        },
        {
            name: 'JavaHome',
            type: 'string',
            defaultValue: ''
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
