import GENERAL_DIRECTIVES from '../generalDirectives';

export const ImNull = {
    label: 'Null',
    type: 'collect',
    name: 'im_null',
    defaultDirectives: 'Module      im_null',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        ...GENERAL_DIRECTIVES
    ]
};
export default null;
