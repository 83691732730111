import GENERAL_DIRECTIVES from '../generalDirectives';

export const ImWseventing = {
    label: 'Windows Event Collector',
    name: 'im_wseventing',
    type: 'collect',
    defaultDirectives: 'Module      im_wseventing',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Address',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'ListenAddr',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'AddPrefix',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'CaptureEventXML',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'ConnectionRetry',
            type: 'number',
            defaultValue: 60
        },
        {
            name: 'ConnectionRetryTotal',
            type: 'number',
            defaultValue: 5
        },
        {
            name: 'Expires',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'GssDllName',
            type: 'string',
            defaultValue: 'gssapi64.dll'
        },
        {
            name: 'HeartBeats',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSAllowUntrusted',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'HTTPSCADir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCAFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCAThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCertFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCertKeyFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCertThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCRLDir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCRLFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSKeyPass',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSSSLCipher',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSSSLCiphersuites',
            type: 'string',
            defaultValue:
                'TLS_AES_256_GCM_SHA384:TLS_CHACHA20_POLY1305_SHA256:TLS_AES_128_GCM_SHA256'
        },
        {
            name: 'HTTPSSSLCompression',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'HTTPSSSLProtocol',
            type: 'select',
            options: ['SSLv2', 'SSLv3', 'TLSv1', 'TLSv1.1', 'TLSv1.2', 'TLSv1.3'],
            defaultValue: [],
            separator: ', '
        },
        {
            name: 'LogConnections',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'MaxElements',
            type: 'number',
            defaultValue: ''
        },
        {
            name: 'MaxEnvelopeSize',
            type: 'number',
            defaultValue: 153600
        },
        {
            name: 'MaxTime',
            type: 'string',
            defaultValue: '30'
        },
        {
            name: 'Port',
            type: 'number',
            defaultValue: 5985
        },
        {
            name: 'Query',
            type: 'multiline-string',
            defaultValue: ''
        },
        {
            name: 'QueryXML',
            type: 'multiline-string-block',
            defaultValue: ''
        },
        {
            name: 'SubscriptionName',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'UseCNGCertificates',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
