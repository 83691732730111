import GENERAL_DIRECTIVES from '../generalDirectives';

export const ImPcap = {
    label: 'Network Packet Capture',
    name: 'im_pcap',
    type: 'collect',
    defaultDirectives: 'Module      im_pcap',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Dev',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'File',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Protocol',
            type: 'block',
            defaultValue: [],
            multiple: true,
            block: [
                {
                    name: 'Type',
                    type: 'select',
                    options: [
                        'ethernet',
                        'ipv4',
                        'ipv6',
                        'ip',
                        'tcp',
                        'udp',
                        'http',
                        'arp',
                        'vlan',
                        'icmp',
                        'pppoe',
                        'dns',
                        'mpls',
                        'gre',
                        'ppp_pptp',
                        'ssl',
                        'sll',
                        'dhcp',
                        'null_loopback',
                        'igmp',
                        'vxlan',
                        'sip',
                        'sdp',
                        'radius',
                        'modbus',
                        'profinet',
                        'dnp3',
                        'bacnet',
                        'iec104',
                        'iec61850',
                        's7',
                        'cotp',
                        'tpkt',
                        'rpc_cl',
                        'packettrailer',
                        'genericpayload',
                        'all'
                    ],
                    defaultValue: '',
                    required: true
                },
                {
                    name: 'Port',
                    type: 'string',
                    defaultValue: ''
                },
                {
                    name: 'Field',
                    label: 'Field',
                    type: 'string',
                    multiple: true,
                    defaultValue: []
                }
            ]
        },
        {
            name: 'Filter',
            type: 'multiline-string',
            defaultValue: ''
        },
        {
            name: 'LogqueueSize',
            type: 'string',
            defaultValue: ''
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
