import GENERAL_DIRECTIVES from '../generalDirectives';

export const ImOdbc = {
    label: 'ODBC',
    name: 'im_odbc',
    type: 'collect',
    defaultDirectives: 'Module      im_odbc',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'ConnectionString',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'SQL',
            type: 'multiline-string',
            defaultValue: '',
            required: true
        },
        {
            name: 'IdIsTimestamp',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'IdType',
            type: 'select',
            options: ['integer', 'timestamp', 'uniqueidentifier'],
            defaultValue: 'integer'
        },
        {
            name: 'MaxIdSQL',
            type: 'number',
            defaultValue: '',
            required: (item) => item.ReadFromLast === 'TRUE'
        },
        {
            name: 'PollInterval',
            type: 'number',
            defaultValue: 1
        },
        {
            name: 'ReadFromLast',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'SavePos',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
