import React, { useContext } from 'react';
import propTypes from 'prop-types';
import { CollectIcon, SendToIcon } from '../utils/Icons';
import RouteEditorModuleItem from '../routeEditorModuleItem';
import { DragAndDropContext } from '../utils/helper';
import { useRelayAddress } from '../../../utils/hooks/useEnrollmentAddress';
import { getActionByName } from '../modules';

function RouteEditorModuleList({ type, list, update, isSolutionPack }) {
    const { addNewAction, getAllModules } = useContext(DragAndDropContext);
    const relayAddress = useRelayAddress();

    const handleNewModule = (mdl) => (data) => {
        mdl.module = data;
        if (mdl.module && Array.isArray(mdl.module.actions)) {
            mdl.module.actions.forEach((actionName) => {
                addNewAction(mdl.actions, getActionByName(actionName));
            });
        }
        const field = mdl.module.fields.find(({ name }) => name === 'Name');
        if (field) {
            const moduleNames = getAllModules()
                .filter(({ module }) => module !== mdl.module && module.name === mdl.module.name)
                .map(({ module }) => module.fields.find(({ name }) => name === 'Name').value);
            let i = 1;
            while (moduleNames.includes(`${mdl.module.name}_${i}`)) {
                i += 1;
            }
            field.value = `${mdl.module.name}_${i}`;
            field.defaultValue = field.value;
        }

        if (mdl.module.name === 'om_raijin') {
            const urlField = mdl.module.fields.find(
                ({ name }) => `${name}`.trim().toLowerCase() === 'url'
            );
            if (urlField) {
                urlField.value = relayAddress;
                urlField.changed = true;
            }
        }

        if (mdl.module.name === 'om_batchcompress') {
            const urlField = mdl.module.fields.find(
                ({ name }) => `${name}`.trim().toLowerCase() === 'host'
            );
            if (urlField) {
                urlField.value = [{ value: relayAddress }];
                urlField.changed = true;
            }
        }

        if (mdl.module.name === 'om_batchcompress_nxlog_platform') {
            const urlField = mdl.module.fields.find(
                ({ name }) => `${name}`.trim().toLowerCase() === 'host'
            );
            if (urlField) {
                urlField.value = [{ value: relayAddress }];
                urlField.changed = true;
            }
        }

        update();
    };

    const handleNewAction = (mdl) => (action) => addNewAction(mdl.actions, action);

    const listModules = list.map((mdl, i) => {
        mdl.key = `${type}-${i}`;
        return mdl;
    });

    return (
        <div className="RouteEditorModuleList" data-testid="RouteEditorModuleList">
            <b>
                {
                    {
                        collect: (
                            <span className="list-title">
                                <span>
                                    <CollectIcon /> Collect From
                                </span>{' '}
                                <small>What you want to collect?</small>
                            </span>
                        ),
                        sendTo: (
                            <span className="list-title">
                                <span>
                                    <SendToIcon /> Send to
                                </span>{' '}
                                <small>Where do you want to send it to?</small>
                            </span>
                        )
                    }[type || 'collect']
                }
            </b>
            <div className="modulelist">
                {listModules.map((mdl) => (
                    <RouteEditorModuleItem
                        key={mdl.key}
                        mdl={mdl}
                        type={type}
                        update={update}
                        onModule={handleNewModule(mdl)}
                        onAction={handleNewAction(mdl)}
                        isSolutionPack={isSolutionPack}
                    />
                ))}
            </div>
        </div>
    );
}

RouteEditorModuleList.defaultProps = {
    type: '',
    list: [],
    update: null,
    isSolutionPack: false
};

RouteEditorModuleList.propTypes = {
    type: propTypes.string,
    list: propTypes.arrayOf(propTypes.shape()),
    update: propTypes.func,
    isSolutionPack: propTypes.bool
};

export default RouteEditorModuleList;
