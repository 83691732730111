export const XmXml = {
    label: 'XML',
    type: 'extension',
    name: 'xm_xml',
    module: 'xm_xml',
    defaultDirectives: `Module      xm_xml`,
    functions: [
        {
            name: 'extract_xml'
        },
        {
            name: 'to_xml'
        },
        {
            name: 'parse_windows_eventlog_xml'
        },
        {
            name: 'parse_xml'
        }
    ],
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'IgnoreRootTag',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'IncludeHiddenFields',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'ParseAttributes',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'PrefixWinEvent',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'RootTag',
            type: 'string',
            defaultValue: ''
        }
    ]
};

export default null;
