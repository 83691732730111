import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { TOAST_TYPES } from '../../utils/constants/ids';
import { createNotification, createNotificationInThunk } from '../../utils/helpers/functions';
import {
    actionMultiAgentsUsingCustomFilter,
    assignTemplateData,
    deleteAgentData,
    deleteMultiAgentsUsingCustomFilter,
    enrollAgent,
    enrollAgentWithTemplate,
    enrollMultipleAgentsUsingCustomFilter,
    exportAgent,
    getAgentConfigData,
    getAgentLogData,
    postAgentModulesOperation,
    refreshAgent,
    renewAgentCertificate,
    renewAgentCertificateUsingCustomFilter,
    restartAgent,
    startAgent,
    stopAgent,
    syncAgentData,
    syncAgentDataUsingCustomFilter,
    unassignTemplate,
    unassignTemplateUsingFilter,
    updateAgentConfig,
    updateConfigData,
    updateConfigDataUsingCustomFilter
} from './agents';
import { updateTemplateData, postTemplateData } from './templates';
import { getFromStorageData, putIntoStorageData } from './storage';

const initialState = {
    loading: false,
    notifications: [],
    notificationsCount: 0,
    error: null,
    persistedNotifications: []
};

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addNotifications: (state, action) => {
            state.notifications = [...state.notifications, action.payload];
        },
        removeNotification: (state, action) => {
            const filteredNotifications = state.notifications.filter(
                (notification) => notification.id !== action.payload.id
            );
            state.notifications = filteredNotifications;
        },
        addPersistedNotification: (state, action) => {
            const notificationData = JSON.parse(localStorage.getItem('notifications')) ?? [];
            state.persistedNotifications = [...notificationData, action.payload];
        },
        removePersistedNotification: (state, action) => {
            const filteredNotifications = state.persistedNotifications.filter(
                (notification) => notification.id !== action.payload.id
            );
            state.persistedNotifications = filteredNotifications;
        },
        updateNotificationsCount: (state, action) => {
            state.notificationsCount = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(enrollAgent.rejected, (state, action) => {
                const notification = createNotificationInThunk(
                    action.payload,
                    TOAST_TYPES.ERROR,
                    `Enroll: ${action.error.message}`,
                    i18next.t('notifications_messages.errors.enroll')
                );
                notification.id = 'enrollAgent.rejected';
                state.notifications.push(notification);
            })
            .addCase(enrollAgent.fulfilled, (state, action) => {
                let message = '';
                if (action?.meta?.arg) {
                    const { hostname } = action.meta.arg;
                    message += `Agent '${hostname}' `;
                }
                message += i18next.t('notifications_messages.success.enroll').toLowerCase();
                const notification = createNotification(TOAST_TYPES.SUCCESS, message);
                notification.id = 'enrollAgent.fulfilled';
                state.notifications.push(notification);
            })

            .addCase(enrollAgentWithTemplate.rejected, (state, action) => {
                const notification = createNotificationInThunk(
                    action.payload,
                    TOAST_TYPES.ERROR,
                    `Enroll: ${action.error.message}`,
                    i18next.t('notifications_messages.errors.enroll')
                );
                notification.id = 'enrollAgentWithTemplate.rejected';
                state.notifications.push(notification);
            })
            .addCase(enrollAgentWithTemplate.fulfilled, (state, action) => {
                let message = '';
                if (action?.meta?.arg) {
                    const { hostname } = action.meta.arg;
                    message += `Agent '${hostname}' `;
                }
                message += i18next.t('notifications_messages.success.enroll').toLowerCase();
                const notification = createNotification(TOAST_TYPES.SUCCESS, message);
                notification.id = 'enrollAgentWithTemplate.fulfilled';
                state.notifications.push(notification);
            })

            .addCase(enrollMultipleAgentsUsingCustomFilter.fulfilled, (state, { payload }) => {
                if (payload.errorMessage) {
                    const notification = createNotification(
                        TOAST_TYPES.ERROR,
                        payload.errorMessage
                    );
                    notification.id = 'enrollMultipleAgentsUsingCustomFilter.rejected';
                    state.notifications.push(notification);
                    return;
                }

                if (payload?.invalidJSONResponse) {
                    const notification = createNotification(
                        TOAST_TYPES.ERROR,
                        i18next.t('notifications_messages.errors.agent_operation', {
                            name: 'Enrollment request'
                        })
                    );
                    notification.id = 'enrollMultipleAgentsUsingCustomFilter.rejected';
                    state.notifications.push(notification);
                    return;
                }

                if (payload?.count) {
                    const notification = createNotification(
                        TOAST_TYPES.SUCCESS,
                        i18next.t('notifications_messages.success.multi_enroll', {
                            count: payload.count
                        })
                    );
                    notification.id = 'enrollMultipleAgentsUsingCustomFilter.fulfilled';
                    state.notifications.push(notification);
                }

                if (payload?.skippedCount) {
                    const notification = createNotification(
                        TOAST_TYPES.WARNING,
                        i18next.t('notifications_messages.warning.enroll', {
                            count: payload?.skippedCount || 1
                        }),
                        i18next.t('notifications_messages.warning.enroll_info', {
                            count: payload?.skippedCount || 1
                        })
                    );
                    notification.id = 'enrollMultipleAgentsUsingCustomFilter.warning';
                    state.notifications.push(notification);
                }

                if (Array.isArray(payload.errors) && payload.errors.length) {
                    const { errors } = payload;
                    const notification = createNotificationInThunk(
                        {
                            message: `Reason to fail: ${
                                errors.length > 1
                                    ? errors
                                          .map(
                                              ({ message, count }) => `${message} (${count} agents)`
                                          )
                                          .join(', ')
                                    : errors[0].message
                            }`
                        },
                        TOAST_TYPES.ERROR,
                        i18next.t(
                            'notifications_messages.errors.enrollment_operation_was_not_executed_for_all_selected_agents',
                            { count: errors.reduce((sum, { count }) => sum + count, 0) }
                        )
                    );
                    notification.id =
                        'enrollment_operation_was_not_executed_for_all_selected_agents';
                    state.notifications.push(notification);
                }
            })
            .addCase(enrollMultipleAgentsUsingCustomFilter.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'enrollment request'
                    })
                );
                notification.id = 'enrollMultipleAgentsUsingCustomFilter.rejected';
                state.notifications.push(notification);
            })
            .addCase(syncAgentDataUsingCustomFilter.fulfilled, (state) => {
                const notification = createNotification(
                    TOAST_TYPES.SUCCESS,
                    i18next.t('notifications_messages.success.agent_operation', {
                        name: 'Data synchronization'
                    })
                );
                notification.id = 'syncAgentDataUsingCustomFilter.fulfilled';
                state.notifications.push(notification);
            })
            .addCase(syncAgentDataUsingCustomFilter.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'data synchronization'
                    })
                );
                notification.id = 'syncAgentDataUsingCustomFilter.rejected';
                state.notifications.push(notification);
            })
            .addCase(postAgentModulesOperation.fulfilled, (state, action) => {
                const name = action.meta.arg?.body?.operation;
                const notification = createNotification(
                    TOAST_TYPES.SUCCESS,
                    i18next.t('notifications_messages.success.agent_operation', { name })
                );
                notification.id = 'postAgentModulesOperation.fulfilled';
                state.notifications.push(notification);
            })
            .addCase(postAgentModulesOperation.rejected, (state, action) => {
                const name = action.meta.arg?.body?.operation;
                const notification = createNotificationInThunk(
                    action.payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', { name })
                );
                notification.id = 'postAgentModulesOperation.rejected';
                state.notifications.push(notification);
            })
            .addCase(getAgentConfigData.fulfilled, (state) => {
                const notification = createNotification(
                    TOAST_TYPES.SUCCESS,
                    i18next.t('notifications_messages.success.agent_operation', {
                        name: 'Get Config'
                    })
                );
                notification.id = 'getAgentConfigData.fulfilled';
                state.notifications.push(notification);
            })
            .addCase(getAgentConfigData.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'Get Config'
                    })
                );
                notification.id = 'getAgentConfigData.rejected';
                state.notifications.push(notification);
            })
            .addCase(deleteAgentData.fulfilled, (state, action) => {
                let message = '';
                if (action?.meta?.arg) {
                    const { hostname } = action.meta.arg;
                    message += `Agent '${hostname}' `;
                }

                message += i18next.t('notifications_messages.success.unenroll').toLowerCase();

                const notification = createNotification(TOAST_TYPES.SUCCESS, message);
                notification.id = 'deleteAgentData.fulfilled';
                state.notifications.push(notification);
            })
            .addCase(deleteAgentData.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'Agent Unenroll'
                    })
                );
                notification.id = 'deleteAgentData.rejected';
                state.notifications.push(notification);
            })
            .addCase(deleteMultiAgentsUsingCustomFilter.fulfilled, (state, { payload }) => {
                if (payload?.count) {
                    const notification = createNotification(
                        TOAST_TYPES.SUCCESS,
                        i18next.t('notifications_messages.success.multi_unenroll', {
                            count: payload.count
                        })
                    );
                    notification.id = 'deleteMultiAgentsUsingCustomFilter.fulfilled';
                    state.notifications.push(notification);
                }

                if (payload?.skippedCount) {
                    const notification = createNotification(
                        TOAST_TYPES.WARNING,
                        i18next.t('notifications_messages.warning.unenroll', {
                            count: payload?.skippedCount || 1
                        }),
                        i18next.t('notifications_messages.warning.unenroll_message', {
                            count: payload?.skippedCount || 1
                        })
                    );
                    notification.id = 'deleteMultiAgentsUsingCustomFilter.warning';
                    state.notifications.push(notification);
                }

                if (Array.isArray(payload.errors) && payload.errors.length) {
                    const { errors } = payload;
                    const notificationErr = createNotificationInThunk(
                        {
                            message: `Reason to fail: ${
                                errors.length > 1
                                    ? errors
                                          .map(
                                              ({ message, count }) => `${message} (${count} agents)`
                                          )
                                          .join(', ')
                                    : errors[0].message
                            }`
                        },
                        TOAST_TYPES.ERROR,
                        i18next.t('notifications_messages.errors.agent_operation', {
                            name: 'unenrollment request',
                            count: errors.reduce((sum, { count }) => sum + count, 0)
                        })
                    );
                    notificationErr.id = 'deleteMultiAgentsUsingCustomFilter.rejected';
                    state.notifications.push(notificationErr);
                }
            })
            .addCase(deleteMultiAgentsUsingCustomFilter.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'unenrollment request'
                    })
                );
                notification.id = 'deleteMultiAgentsUsingCustomFilter.rejected';
                state.notifications.push(notification);
            })
            .addCase(updateConfigDataUsingCustomFilter.fulfilled, (state, { payload }) => {
                if (payload?.count) {
                    const notification = createNotification(
                        TOAST_TYPES.SUCCESS,
                        i18next.t('notifications_messages.success.multi_agent_operation', {
                            count: payload.count,
                            operation: 'updated'
                        })
                    );
                    notification.id = 'updateConfigDataUsingCustomFilter.fulfilled';
                    state.notifications.push(notification);
                }

                if (Array.isArray(payload.errors) && payload.errors.length) {
                    payload.errors.forEach((errPayload) => {
                        if (payload.errors.length > 1) {
                            delete errPayload.agentId;
                        }
                        const notificationErr = createNotificationInThunk(
                            errPayload,
                            TOAST_TYPES.ERROR,
                            i18next.t('notifications_messages.errors.agent_operation', {
                                name: 'updated config request'
                            })
                        );
                        notificationErr.id = 'updateConfigDataUsingCustomFilter.rejected';
                        state.notifications.push(notificationErr);
                    });
                }
            })
            .addCase(updateConfigDataUsingCustomFilter.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'updated config request'
                    })
                );
                notification.id = 'updateConfigDataUsingCustomFilter.rejected';
                state.notifications.push(notification);
            })
            .addCase(actionMultiAgentsUsingCustomFilter.fulfilled, (state, { payload }) => {
                if (payload.count) {
                    const notification = createNotification(
                        TOAST_TYPES.SUCCESS,
                        i18next.t('notifications_messages.success.multi_agent_operation', {
                            count: payload.count,
                            operation: {
                                start: 'started',
                                restart: 'restarted',
                                stop: 'stopped'
                            }[payload.operation]
                        })
                    );
                    notification.id = 'actionMultiAgentsUsingCustomFilter.fulfilled';
                    state.notifications.push(notification);
                }

                if (payload.skippedCount) {
                    const notification = createNotification(
                        TOAST_TYPES.WARNING,
                        i18next.t(
                            `notifications_messages.warning.${
                                payload.operation === 'restart'
                                    ? 'restart'
                                    : 'multi_agent_operation'
                            }`,
                            {
                                count: payload.skippedCount,
                                operation: {
                                    start: 'Start',
                                    restart: 'Restart',
                                    stop: 'Stop'
                                }[payload.operation]
                            }
                        ),
                        payload.operation === 'restart'
                            ? i18next.t('notifications_messages.warning.restart_info', {
                                  count: payload.skippedCount
                              })
                            : undefined
                    );
                    notification.id = 'actionMultiAgentsUsingCustomFilter.skipped';
                    state.notifications.push(notification);
                }

                if (Array.isArray(payload.errors) && payload.errors.length) {
                    const { errors } = payload;
                    const notificationErr = createNotificationInThunk(
                        {
                            message: `Reason to fail: ${
                                errors.length > 1
                                    ? errors
                                          .map(
                                              ({ message, count }) => `${message} (${count} agents)`
                                          )
                                          .join(', ')
                                    : errors[0].message
                            }`
                        },
                        TOAST_TYPES.ERROR,
                        i18next.t('notifications_messages.errors.agent_operation', {
                            name: {
                                start: 'start',
                                restart: 'restart',
                                stop: 'stop'
                            }[payload.operation],
                            count: errors.reduce((sum, { count }) => sum + count, 0)
                        })
                    );
                    notificationErr.id = 'actionMultiAgentsUsingCustomFilter.rejected';
                    state.notifications.push(notificationErr);
                }
            })
            .addCase(actionMultiAgentsUsingCustomFilter.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'request'
                    })
                );
                notification.id = 'actionMultiAgentsUsingCustomFilter.rejected';
                state.notifications.push(notification);
            })
            .addCase(restartAgent.fulfilled, (state) => {
                const notification = createNotification(
                    TOAST_TYPES.SUCCESS,
                    i18next.t('notifications_messages.success.agent_operation', {
                        name: 'Restart'
                    })
                );
                notification.id = 'restartAgent.fulfilled';
                state.notifications.push(notification);
            })
            .addCase(restartAgent.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'Restart'
                    })
                );
                notification.id = 'restartAgent.rejected';
                state.notifications.push(notification);
            })
            .addCase(startAgent.fulfilled, (state) => {
                const notification = createNotification(
                    TOAST_TYPES.SUCCESS,
                    i18next.t('notifications_messages.success.agent_operation', {
                        name: 'Start'
                    })
                );
                notification.id = 'startAgent.fulfilled';
                state.notifications.push(notification);
            })
            .addCase(startAgent.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'Start'
                    })
                );
                notification.id = 'startAgent.rejected';
                state.notifications.push(notification);
            })
            .addCase(stopAgent.fulfilled, (state) => {
                const notification = createNotification(
                    TOAST_TYPES.SUCCESS,
                    i18next.t('notifications_messages.success.agent_operation', {
                        name: 'Stop'
                    })
                );
                notification.id = 'stopAgent.fulfilled';
                state.notifications.push(notification);
            })
            .addCase(stopAgent.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'Stop'
                    })
                );
                notification.id = 'stopAgent.rejected';
                state.notifications.push(notification);
            })
            .addCase(syncAgentData.fulfilled, (state, { payload }) => {
                if (Array.isArray(payload)) {
                    const errors = payload.filter(({ status }) => status !== 'success');
                    const successful = payload.length - errors.length;
                    const notification = createNotification(
                        TOAST_TYPES.SUCCESS,
                        i18next.t('notifications_messages.success.multi_agent_operation', {
                            operation: 'synchronized data',
                            count: successful
                        })
                    );
                    notification.id = 'syncAgentData.fulfilled';
                    state.notifications.push(notification);
                    if (errors.length) {
                        const errNotification = createNotification(
                            TOAST_TYPES.ERROR,
                            i18next.t('notifications_messages.errors.agent_operation', {
                                name: 'data synchronization',
                                count: errors.length
                            }),
                            `agent id: ${errors.map(({ id }) => id).join(', ')}`
                        );
                        errNotification.id = 'syncAgentData.error';
                        state.notifications.push(errNotification);
                    }
                } else {
                    const notification = createNotification(
                        TOAST_TYPES.SUCCESS,
                        i18next.t('notifications_messages.success.agent_operation', {
                            name: 'Data synchronization'
                        })
                    );
                    notification.id = 'syncAgentData.fulfilled';
                    state.notifications.push(notification);
                }
            })
            .addCase(syncAgentData.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'Data synchronization'
                    })
                );
                notification.id = 'syncAgentData.rejected';
                state.notifications.push(notification);
            })
            .addCase(refreshAgent.fulfilled, (state) => {
                const notification = createNotification(
                    TOAST_TYPES.SUCCESS,
                    i18next.t('notifications_messages.success.agent_operation', {
                        name: 'Refresh'
                    })
                );
                notification.id = 'refreshAgent.fulfilled';
                state.notifications.push(notification);
            })
            .addCase(refreshAgent.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'Refresh'
                    })
                );
                notification.id = 'refreshAgent.rejected';
                state.notifications.push(notification);
            })
            .addCase(renewAgentCertificate.fulfilled, (state) => {
                const notification = createNotification(
                    TOAST_TYPES.SUCCESS,
                    i18next.t('notifications_messages.success.agent_operation', {
                        name: 'Renew Certificate'
                    })
                );
                notification.id = 'renewCertificate.fulfilled';
                state.notifications.push(notification);
            })
            .addCase(renewAgentCertificateUsingCustomFilter.fulfilled, (state, { payload }) => {
                const successCount = Array.isArray(payload)
                    ? payload.filter(({ status }) => status === 'success').length
                    : 0;
                const errorCount = Array.isArray(payload) ? payload.length - successCount : 0;
                if (successCount) {
                    const notification = createNotification(
                        TOAST_TYPES.SUCCESS,
                        i18next.t(
                            'notifications_messages.success.multi_agent_certificates_renewal',
                            {
                                count: successCount
                            }
                        )
                    );
                    notification.id = 'renewAgentCertificateUsingCustomFilter.fulfilled';
                    state.notifications.push(notification);
                }
                if (errorCount) {
                    const notification = createNotification(
                        TOAST_TYPES.ERROR,
                        i18next.t(
                            'notifications_messages.errors.multi_agent_certificates_renewal',
                            {
                                count: errorCount
                            }
                        )
                    );
                    notification.id = 'renewAgentCertificateUsingCustomFilter.rejected';
                    state.notifications.push(notification);
                }
            })
            .addCase(renewAgentCertificateUsingCustomFilter.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'Renew Certificate'
                    })
                );
                notification.id = 'renewAgentCertificateUsingCustomFilter.rejected';
                state.notifications.push(notification);
            })
            .addCase(exportAgent.fulfilled, (state) => {
                const notification = createNotification(
                    TOAST_TYPES.SUCCESS,
                    i18next.t('notifications_messages.success.agent_operation', {
                        name: 'Export'
                    })
                );
                notification.id = 'exportAgent.fulfilled';
                state.notifications.push(notification);
            })
            .addCase(exportAgent.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'Export'
                    })
                );
                notification.id = 'exportAgent.rejected';
                state.notifications.push(notification);
            })
            .addCase(updateConfigData.fulfilled, (state) => {
                const notification = createNotification(
                    TOAST_TYPES.SUCCESS,
                    i18next.t('notifications_messages.success.agent_operation', {
                        name: 'Update Config'
                    })
                );
                notification.id = 'updateConfigData.fulfilled';
                state.notifications.push(notification);
            })
            .addCase(updateConfigData.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'Update Config'
                    })
                );
                notification.id = 'updateConfigData.rejected';
                state.notifications.push(notification);
            })
            .addCase(updateAgentConfig.fulfilled, (state) => {
                const notification = createNotification(
                    TOAST_TYPES.SUCCESS,
                    i18next.t('notifications_messages.success.agent_operation', {
                        name: 'Agent Config Update'
                    })
                );
                notification.id = 'updateAgentConfig.fulfilled';
                state.notifications.push(notification);
            })
            .addCase(updateAgentConfig.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'Agent Config Update'
                    })
                );
                notification.id = 'updateAgentConfig.rejected';
                state.notifications.push(notification);
            })
            .addCase(updateTemplateData.fulfilled, (state, { meta }) => {
                const templateName = meta?.arg?.body?.name;
                const notification = createNotification(
                    TOAST_TYPES.SUCCESS,
                    i18next.t('notifications_messages.success.agent_operation', {
                        name: `Update template ${templateName || ''}`
                    })
                );
                notification.id = 'updateTemplateData.fulfilled';
                state.notifications.push(notification);
            })
            .addCase(updateTemplateData.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'Update Template'
                    })
                );
                notification.id = 'updateTemplateData.rejected';
                state.notifications.push(notification);
            })
            .addCase(getAgentLogData.fulfilled, (state) => {
                const notification = createNotification(
                    TOAST_TYPES.SUCCESS,
                    i18next.t('notifications_messages.success.agent_operation', {
                        name: 'Get Log'
                    })
                );
                notification.id = 'getAgentLogData.fulfilled';
                state.notifications.push(notification);
            })
            .addCase(getAgentLogData.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'Get Log'
                    })
                );
                notification.id = 'getAgentLogData.rejected';
                state.notifications.push(notification);
            })
            .addCase(assignTemplateData.fulfilled, (state, { payload }) => {
                let notification;
                if (typeof payload === 'object' && (payload[0]?.error || payload?.error)) {
                    let error = '';
                    if (Array.isArray(payload) && payload.length && payload[0]?.error) {
                        error =
                            typeof payload[0].error === 'string'
                                ? payload[0].error
                                : payload[0].error?.message;
                    } else if (payload?.error) {
                        error =
                            typeof payload.error === 'string'
                                ? payload.error
                                : payload.error?.message;
                    }

                    if (typeof error === 'string' && error.trim().startsWith('{')) {
                        try {
                            error = JSON.parse(error);
                            if (error && error.message) error = error.message;
                        } catch (e) {
                            // error remains unchanged if parsing fails
                        }
                    }
                    notification = createNotification(TOAST_TYPES.ERROR, error);
                } else {
                    notification = createNotification(
                        TOAST_TYPES.SUCCESS,
                        i18next.t('notifications_messages.success.agent_operation', {
                            name: 'Assign Template'
                        })
                    );
                }

                notification.id = 'assignTemplate.fulfilled';
                state.notifications.push(notification);
            })
            .addCase(assignTemplateData.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'Assign Template'
                    })
                );
                notification.id = 'assignTemplate.rejected';
                state.notifications.push(notification);
            })
            .addCase(unassignTemplate.rejected, (state) => {
                const successNotification = createNotification(
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'Unassign Template'
                    })
                );
                successNotification.id = 'unassignTemplate.rejected';
                state.notifications.push(successNotification);
            })
            .addCase(unassignTemplate.fulfilled, (state) => {
                const successNotification = createNotification(
                    TOAST_TYPES.SUCCESS,
                    i18next.t('notifications_messages.success.agent_operation', {
                        name: 'Unassign Template'
                    })
                );
                successNotification.id = 'unassignTemplate.fulfilled';
                state.notifications.push(successNotification);
            })
            .addCase(unassignTemplateUsingFilter.rejected, (state) => {
                const successNotification = createNotification(
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'Unassign Template'
                    })
                );
                successNotification.id = 'unassignTemplateUsingFilter.rejected';
                state.notifications.push(successNotification);
            })
            .addCase(unassignTemplateUsingFilter.fulfilled, (state, { payload }) => {
                const errors = payload.filter((res) => res.error);
                if (errors.length > 0) {
                    const errNotification = createNotificationInThunk(
                        {
                            message: `Unassign template failed for ${
                                errors.length
                            } agent(s) with message "${errors
                                .map((err) => err.error)
                                .filter((err, i, arr) => arr.indexOf(err) === i)
                                .join('", "')}".`
                        },
                        TOAST_TYPES.ERROR,
                        i18next.t('notifications_messages.errors.agent_operation', {
                            name: 'Unassign Template'
                        })
                    );
                    errNotification.id = 'unassignTemplateUsingFilter.rejected';
                    state.notifications.push(errNotification);
                }
                const success = payload.length - errors.length;

                if (success > 0) {
                    const successNotification = createNotification(
                        TOAST_TYPES.SUCCESS,
                        i18next.t('notifications_messages.success.agent_operation', {
                            name: 'Unassign Template',
                            count: success
                        })
                    );
                    successNotification.id = 'unassignTemplateUsingFilter.fulfilled';
                    state.notifications.push(successNotification);
                }
            })
            .addCase(postTemplateData.fulfilled, (state) => {
                const notification = createNotification(
                    TOAST_TYPES.SUCCESS,
                    i18next.t('notifications_messages.success.agent_operation', {
                        name: 'Create Template'
                    })
                );
                notification.id = 'createTemplate.fulfilled';
                state.notifications.push(notification);
            })
            .addCase(postTemplateData.rejected, (state, { payload }) => {
                const notification = createNotificationInThunk(
                    payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.agent_operation', {
                        name: 'Create Template'
                    })
                );
                notification.id = 'createTemplate.rejected';
                state.notifications.push(notification);
            })
            .addCase(putIntoStorageData.fulfilled, (state, action) => {
                if (!action.meta?.arg?.notification) return;
                const notification = createNotification(
                    TOAST_TYPES.SUCCESS,
                    i18next.t('notifications_messages.success.settings_update')
                );
                notification.id = 'putIntoStorageData.fulfilled';
                state.notifications.push(notification);
            })
            .addCase(putIntoStorageData.rejected, (state, action) => {
                if (!action.meta?.arg?.notification) return;
                const notification = createNotificationInThunk(
                    action.payload,
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.settings_update')
                );
                notification.id = 'putIntoStorageData.rejected';
                state.notifications.push(notification);
            })
            .addCase(getFromStorageData.rejected, () => {})
            .addDefaultCase((state, action) => {
                if (action?.type?.endsWith('/rejected') && !!action.payload) {
                    const notification = createNotificationInThunk(
                        action.payload,
                        TOAST_TYPES.ERROR
                    );
                    notification.id = 'anyAction.rejected';
                    state.notifications.push(notification);
                }
            });
    }
});

export const selectNotifications = (state) => state.notifications.notifications;

export const selectPersistedNotifications = (state) => state.notifications.persistedNotifications;

export const selectNotificationsCount = (state) => state.notifications.notificationsCount;

export const {
    addNotifications,
    removeNotification,
    addPersistedNotification,
    removePersistedNotification,
    updateNotificationsCount
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
