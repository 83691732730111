const GENERAL_DIRECTIVES = [
    {
        name: 'InputType',
        type: 'string',
        defaultValue: ''
    },
    {
        name: 'Define',
        type: 'string',
        multiple: true,
        defaultValue: [],
        emptyByDefault: true
    },
    {
        name: 'Schedule',
        type: 'block',
        defaultValue: [],
        multiple: true,
        emptyByDefault: true,
        block: [
            {
                name: 'Every',
                type: 'string',
                defaultValue: ''
            },
            {
                name: 'First',
                type: 'string',
                defaultValue: ''
            },
            {
                name: 'RunCount',
                type: 'string',
                defaultValue: ''
            },
            {
                name: 'When',
                type: 'string',
                defaultValue: ''
            },
            {
                name: 'Exec',
                type: 'multiline-string-block',
                defaultValue: '',
                required: true
            }
        ]
    },
    {
        name: 'include_stdout',
        type: 'string',
        defaultValue: ''
    }
];

export default GENERAL_DIRECTIVES;
