export const XmCef = {
    label: 'CEF',
    type: 'extension',
    name: 'xm_cef',
    module: 'xm_cef',
    defaultDirectives: `Module      xm_cef`,
    functions: [
        {
            name: 'to_cef'
        },
        {
            name: 'parse_cef'
        }
    ],
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'IncludeHiddenFields',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        }
    ]
};

export default null;
