import React from 'react';
import propTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';
import { Checkbox } from '@nxlog/common-ui/components';
import './tableSidebarFilters.scss';
import classNames from 'classnames';

export default function TableSidebarFilters(props) {
    const {
        applyFilter,
        removeFilter,
        filters,
        appliedFilters,
        initialFilters = [],
        disabledFilterIds = []
    } = props;

    const handleChange = (item) => (value) => {
        if (value) {
            applyFilter(item);
        } else {
            removeFilter(item);
        }
    };

    const initialIds = initialFilters.map((f) => f.id);
    // this keeps initial filters open on the first load
    const activeKeys = filters.reduce((acc, filter, index) => {
        if (filter.data.find((f) => initialIds.includes(f.id))) acc.push(index);
        return acc;
    }, []);

    return (
        <div className="table-sidebar-filters">
            <Accordion defaultActiveKey={activeKeys} alwaysOpen>
                {filters.map((filter, index) => (
                    <Accordion.Item
                        data-testid="table-sidebar-filters-category"
                        eventKey={index}
                        key={filter.name}
                        className="single-filter"
                    >
                        <Accordion.Header
                            data-testid="table-sidebar-filters-category-name"
                            className="filter-label"
                        >
                            {filter.name}
                        </Accordion.Header>
                        <Accordion.Body className="filter-body">
                            {filter.data.map((item) => (
                                <div
                                    data-testid="table-sidebar-filters-item-name"
                                    key={item.id}
                                    className={classNames(
                                        'single-filter-body',
                                        appliedFilters.includes(item) && 'active'
                                    )}
                                >
                                    <Checkbox
                                        checked={appliedFilters.map((f) => f.id).includes(item.id)}
                                        onChange={handleChange(item)}
                                        textLabel={item.label}
                                        svgClassName="filter-checkbox"
                                        itemsCount={item.itemsCount?.toString() || ''}
                                        disabled={
                                            initialFilters.map((f) => f.id).includes(item.id) ||
                                            disabledFilterIds.includes(item.id)
                                        }
                                    />
                                </div>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
}

TableSidebarFilters.defaultProps = {
    filters: [],
    appliedFilters: [],
    removeFilter: () => {},
    applyFilter: () => {},
    initialFilters: [],
    disabledFilterIds: []
};

TableSidebarFilters.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    filters: propTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    appliedFilters: propTypes.array,
    removeFilter: propTypes.func,
    applyFilter: propTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    initialFilters: propTypes.array,
    disabledFilterIds: propTypes.arrayOf(propTypes.string)
};
