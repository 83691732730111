import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import cc from 'classnames';
import WithTooltip from '@nxlog/common-ui/dist/components/with_tooltip';
import { DragAndDropContext } from '../utils/helper';
import {
    AddIcon,
    DeleteIcon,
    IconConfig,
    IconDelete,
    IconMove,
    WarningModuleIcon
} from '../utils/Icons';

export function ItemEmetter({ className, children, data, testid, layout, disabled }) {
    const elm = useRef(null);
    const { setData } = useContext(DragAndDropContext);

    const handleDragStart = (e) => {
        if (disabled) return;
        e.stopPropagation();
        setData({ ...data });
        if (e?.target) {
            const crt = e.target.cloneNode(true);
            crt.className = `ItemEmetter isDragging ${className || ''}`.trim();
            crt.style.position = 'absolute';
            crt.style.top = '-10000px';
            crt.style.left = '-10000px';
            crt.style.borderRadius = '8px';
            crt.removeAttribute('data-testid');
            document.body.appendChild(crt);
            e.dataTransfer?.setDragImage(crt, crt.offsetWidth / 2, 30);
        }
    };

    const handleDragEnd = () => {
        document.querySelectorAll('.isDragging').forEach((item) => item.remove());
        setData(null);
    };

    if (layout === 'delete-action') {
        return (
            <div
                ref={elm}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                draggable="true"
                data-testid={testid}
                className={`ItemEmetter-layout ${className || ''}`}
            >
                {children}
                <DeleteIcon />
            </div>
        );
    }

    return (
        <div
            ref={elm}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            draggable={!disabled}
            className={`ItemEmetter${disabled ? ' disabled' : ''} ${className || ''}`.trim()}
            data-testid={testid}
        >
            <IconMove />
            <span className="ItemEmetter-title">{children}</span>
            <AddIcon />
        </div>
    );
}

ItemEmetter.defaultProps = {
    className: '',
    testid: '',
    children: null,
    layout: '',
    data: null,
    disabled: false
};

ItemEmetter.propTypes = {
    className: PropTypes.string,
    testid: PropTypes.string,
    layout: PropTypes.string,
    children: PropTypes.node,
    data: PropTypes.shape(),
    disabled: PropTypes.bool
};

export function ItemReceiver(props) {
    const {
        className,
        label,
        testid,
        type,
        onDrop,
        hiddenReceiver,
        displayOnlyOnDragging,
        helperText,
        children
    } = props;
    const [dropZoneCanDrop, setDropZoneCanDrop] = useState(false);
    const { data, setData } = useContext(DragAndDropContext);
    const dropZoneAllowed = Boolean(data) && data.type === type;

    function handleDragEnter(e) {
        if (data && type.split(',').includes(data.type)) {
            e.preventDefault();
            setDropZoneCanDrop(true);
        }
    }

    function handleDragLeave(e) {
        e.preventDefault();
        setDropZoneCanDrop(false);
    }

    function handleDrop(e) {
        if (onDrop && data && type.split(',').includes(data.type)) {
            e.preventDefault();
            e.stopPropagation();
            setDropZoneCanDrop(false);
            setData(null);
            onDrop(cloneDeep(data), data);
        }
    }

    function handleDragOver(e) {
        e.preventDefault();
        if (data && type.split(',').includes(data.type)) {
            setDropZoneCanDrop(true);
        }
    }
    const visible = !(
        displayOnlyOnDragging &&
        (!data || (data && !type.split(',').includes(data.type)))
    );

    if (hiddenReceiver) {
        return (
            <WithTooltip message={helperText} placement="bottom">
                <div
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    data-testid={testid}
                    className={cc({
                        'dnd-drop-receiver': true,
                        'dnd-drop-zone-allowed': dropZoneAllowed,
                        'dnd-drop-zone-can-drop': dropZoneCanDrop,
                        [className]: Boolean(className)
                    })}
                >
                    {children}
                </div>
            </WithTooltip>
        );
    }

    return (
        <WithTooltip message={helperText} placement="bottom">
            <div
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                data-testid={testid}
                className={cc({
                    'dnd-drop-item': true,
                    'dnd-drop-zone': true,
                    'dnd-drop-item-hidden': !visible,
                    'dnd-drop-zone-allowed': dropZoneAllowed,
                    'dnd-drop-zone-can-drop': dropZoneCanDrop,
                    [className]: Boolean(className)
                })}
            >
                {label}
            </div>
        </WithTooltip>
    );
}

ItemReceiver.defaultProps = {
    className: '',
    label: '',
    type: '',
    testid: '',
    children: null,
    hiddenReceiver: false,
    displayOnlyOnDragging: false,
    helperText: '',
    onDrop: null
};

ItemReceiver.propTypes = {
    className: PropTypes.string,
    label: PropTypes.node,
    type: PropTypes.string,
    testid: PropTypes.string,
    hiddenReceiver: PropTypes.bool,
    displayOnlyOnDragging: PropTypes.bool,
    helperText: PropTypes.string,
    children: PropTypes.node,
    onDrop: PropTypes.func
};

export function ItemDisplay(props) {
    const {
        className,
        children,
        onClick,
        testid,
        uncompleted,
        flag,
        isSolutionPack,
        deleteButtonDisabled
    } = props;

    return (
        <div
            className={cc({
                'dnd-drop-item': true,
                'dnd-drop-display': true,
                'dnd-drop-display-not-completed': uncompleted,
                'dnd-drop-item-solution-pack': isSolutionPack,
                [className]: Boolean(className)
            })}
        >
            <div className="content" data-testid={`${testid}-content`}>
                {className === 'action' ? (
                    <WithTooltip message={children} placement="bottom">
                        <span>{children}</span>
                    </WithTooltip>
                ) : (
                    children
                )}
                {flag ? <span className="ItemDisplayFlag">{flag}</span> : null}
            </div>
            {!isSolutionPack && (
                <div className="actions">
                    {uncompleted ? (
                        <WithTooltip
                            message="Please fill in the required fields &#9881;"
                            placement="top"
                        >
                            <WarningModuleIcon />
                        </WithTooltip>
                    ) : null}
                    <IconConfig
                        testid={`${testid}-edit-btn`}
                        onClick={() => (onClick ? onClick('config') : null)}
                    />
                    <WithTooltip
                        message={
                            deleteButtonDisabled
                                ? 'Unable to delete an extension that is still in use'
                                : 'Delete'
                        }
                        placement="bottom"
                    >
                        <IconDelete
                            testid={`${testid}-delete-btn`}
                            onClick={() =>
                                onClick && !deleteButtonDisabled ? onClick('delete') : null
                            }
                            className={
                                deleteButtonDisabled
                                    ? 'configediticon module-delete-btn-disabled'
                                    : 'configediticon'
                            }
                        />
                    </WithTooltip>
                </div>
            )}
        </div>
    );
}

ItemDisplay.defaultProps = {
    className: '',
    testid: '',
    children: null,
    onClick: null,
    uncompleted: false,
    flag: null,
    isSolutionPack: false,
    deleteButtonDisabled: false
};

ItemDisplay.propTypes = {
    className: PropTypes.string,
    testid: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func,
    uncompleted: PropTypes.bool,
    flag: PropTypes.string,
    isSolutionPack: PropTypes.bool,
    deleteButtonDisabled: PropTypes.bool
};
