import GENERAL_DIRECTIVES from '../generalDirectives';

export const OmAmazons3 = {
    label: 'Amazon S3',
    name: 'om_amazons3',
    type: 'sendTo',
    defaultDirectives: 'Module      om_amazons3',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Bucket',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'Region',
            type: 'select',
            options: [
                'us-east-1',
                'us-east-2',
                'us-west-1',
                'us-west-2',
                'ca-central-1',
                'af-south-1',
                'ap-east-1',
                'ap-south-1',
                'ap-northeast-1',
                'ap-northeast-2',
                'ap-northeast-3',
                'ap-southeast-1',
                'ap-southeast-2',
                'cn-north-1',
                'cn-northwest-1',
                'eu-north-1',
                'eu-central-1',
                'eu-west-1',
                'eu-west-2',
                'eu-west-3',
                'sa-east-1',
                'me-south-1',
                'nyc3',
                'ams3',
                'sgp1',
                'fra1',
                'ru-central1',
                'wa-us-east-1',
                'wa-us-east-2',
                'wa-us-west-1',
                'wa-eu-central-1'
            ],
            defaultValue: '',
            required: true
        },
        {
            name: 'Server',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'AccessKey',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Connections',
            type: 'number',
            defaultValue: 1
        },
        {
            name: 'OutputType',
            type: 'select',
            options: ['Binary', 'Dgram', 'LineBased', 'LineBased_CRLF', 'LineBased_LF'],
            defaultValue: 'LineBased'
        },
        {
            name: 'PathStyle',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'S3BatchSize',
            type: 'string',
            defaultValue: '64MB'
        },
        {
            name: 'SecretKey',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'URL',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Reconnect',
            type: 'number',
            defaultValue: ''
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
