/* eslint-disable no-use-before-define */
// eslint-disable-next-line import/no-cycle
import { actions, collectModules, sendToModules, extensions } from '../modules';

export function getValueOfNameField(module) {
    const nameField = module.fields.find(({ name }) => `${name}`.trim().toLowerCase() === 'name');
    let name = nameField ? nameField.value : null;
    if (name && name.match(/_[0-9]+$/i)) {
        name = name.split('_');
        name.pop();
        name = name.join('_');
    }
    return name;
}

export function getFieldReference(module, parentsFields) {
    let list = [];
    switch (module.type) {
        case 'collect':
            list = collectModules;
            break;
        case 'sendTo':
            list = sendToModules;
            break;
        case 'extension':
            list = extensions;
            break;
        case 'comment':
            list = [];
            break;
        default:
            return null;
    }
    let refModule = list.filter((elm) => elm.moduleName === module.moduleName);
    const name = getValueOfNameField(module);
    if (refModule.length > 1 && name) {
        const refModuleOfSameName = refModule.filter(
            (elm) => `${elm.name}`.trim().toLowerCase() === name.trim().toLowerCase()
        );
        if (refModuleOfSameName.length) {
            refModule = refModuleOfSameName;
        }
    }
    if (refModule.length) {
        let fields = [...refModule[0].fields];
        let selectedField = null;
        if (!Array.isArray(parentsFields)) parentsFields = [parentsFields];
        parentsFields.forEach((field) => {
            selectedField = fields.find((elm) => elm.name === field.name);
            fields = [];
            if (selectedField) {
                if (selectedField.type === 'block') {
                    fields = Array.isArray(selectedField.block) ? selectedField.block : [];
                }
            }
        });
        return selectedField;
    }
    return null;
}

export function moduleIsCompleted(mdl) {
    if (!Array.isArray(mdl.fields)) return true;
    const data = getModuleFieldsAsObject(mdl.fields);
    const requiredFields = mdl.fields
        .filter((field) => {
            if (`${field.name}`.toLowerCase().trim() === 'module' || field.isNotDirective)
                return false;
            if (!field.if) return true;
            return field.if(data);
        })
        .filter((field) => {
            if (typeof field.required === 'function') return field.required(data);
            if (field.required === true) return true;
            return false;
        })
        .filter((field) => ['', null, undefined].includes(field.value));
    return requiredFields.length === 0;
}

export function getModuleName(mdl) {
    const field = mdl.module.fields.find(({ name }) => name === 'Name');
    if (field) return field.value;
    return null;
}

export function getActionConfigCode(action, level = 2, codeOnly = false) {
    const refAction = actions.find(({ label }) => label === action.label);
    if (refAction && refAction.getCode) {
        const code = refAction.getCode(action, level);
        if (code) {
            const space = ' '.repeat(level);
            if (codeOnly) return `${space} ${code}`;
            if (code.split('\n').length === 1 && (action.isFlag || refAction.inline)) {
                return `${space}Exec ${code}`;
            }
            return `${space}<Exec>\n${code}\n${space}</Exec>`;
        }
    }
    return '';
}

function getExtentions(actionsList, list, mdl, ConfigItem, level = 0) {
    const lineNbrLastExt =
        Array.isArray(ConfigItem.extensions) && ConfigItem.extensions.length
            ? ConfigItem.extensions[ConfigItem.extensions.length - 1].endLineNbr
            : mdl.endLineNbr;
    if (Array.isArray(actionsList)) {
        actionsList.forEach((action) => {
            const refAction = actions.find(({ label }) => label === action.label);
            if (refAction?.extension && Array.isArray(refAction?.extension)) {
                refAction.extension
                    .filter(
                        (elm) =>
                            !list
                                .map((ext) => ext.content)
                                .join('\n')
                                .includes(elm.content)
                    )
                    .filter(
                        (elm) =>
                            !Array.isArray(ConfigItem.extensions) ||
                            !ConfigItem.extensions.find(
                                (ext) =>
                                    `${ext.module}`.trim().toLowerCase() ===
                                    `${elm.module}`.trim().toLowerCase()
                            )
                    )
                    .forEach((elm) => {
                        list.push({
                            index: lineNbrLastExt + list.length + 1,
                            content: elm.content
                        });
                    });
            }
            if (refAction && refAction.label.includes('If-Else Condition')) {
                getExtentions(refAction.then, list, mdl, ConfigItem, level + 1);
                getExtentions(refAction.else, list, mdl, ConfigItem, level + 1);
            }
        });
    }
    if (level === 0 && mdl && mdl.module.extension && Array.isArray(mdl.module.extension)) {
        mdl.module.extension
            .filter(
                (elm) =>
                    !list
                        .map((ext) => ext.content)
                        .join('\n')
                        .includes(elm.content)
            )
            .filter(
                (elm) =>
                    !Array.isArray(ConfigItem.extensions) ||
                    !ConfigItem.extensions.find(
                        (ext) =>
                            `${ext.module}`.trim().toLowerCase() ===
                            `${elm.module}`.trim().toLowerCase()
                    )
            )
            .forEach((elm) => {
                list.push({
                    index: lineNbrLastExt + list.length + 1,
                    content: elm.content
                });
            });
    }
}

export function getModuleFieldsAsObject(fields) {
    const fieldsObj = {};

    if (Array.isArray(fields)) {
        const setFieldsObjValue = (field) => {
            if (field.multiple && (!field.defaultValue || !field.defaultValue.length)) {
                field.defaultValue = field.emptyByDefault ? [] : [{ value: '' }];
            }

            const value = field.changed ? field.value : field.defaultValue;

            fieldsObj[field.name] =
                typeof field.setValueAs === 'function' ? field.setValueAs(value, fieldsObj) : value;
        };
        fields.filter((field) => !field.isNotDirective).forEach(setFieldsObjValue);
        fields.filter((field) => field.isNotDirective).forEach(setFieldsObjValue);
    }

    return fieldsObj;
}

function isChanged(field, value, defaultValue) {
    if (['block', 'comment'].includes(field.type) || field.multiple) {
        return true;
    }
    return value && `${value}`.trim().toUpperCase() !== `${defaultValue}`.trim().toUpperCase();
}

function generateCodeByBlock(module, _fields, data, parentFields = []) {
    let fields = _fields
        .filter((field) => !field.isName && !field.isNotDirective)
        .map((field) => {
            if (['comment', 'empty-line'].includes(field.type)) return field;
            const refField = getFieldReference(module, [...parentFields, field]);
            field.changed = isChanged(field, data[field.name], refField.defaultValue);
            return { ...refField, ...field };
        })
        .filter((field) => !field.if || field.if(data))
        .filter(
            ({ changed, name, requiredInConfigText }) =>
                (name !== 'Name' || parentFields.length) && (changed || requiredInConfigText)
        );
    const leftWidth = fields.reduce(
        (w, field) => (w < field.name.length ? field.name.length : w),
        0
    );
    fields = fields.map((field) => ({ ...field, leftWidth: Math.min(leftWidth, 20) }));

    const directives = fields
        .map((field) => getDirectiveConfigCode(field, module, data, parentFields))
        .reduce((acc, cur) => [...acc, ...cur], [])
        .filter((elm) => elm);

    directives.sort((a, b) => {
        if (!a.lineNbr) return 1;
        if (!b.lineNbr) return -1;
        return a.lineNbr - b.lineNbr;
    });

    return directives.map(({ content }) => content).join('\n');
}

function getDirectiveConfigCode(_field, module, data, parentFields) {
    const { name, type, multiple, separator, leftWidth, fields, block, lineNbr } = _field;
    const spaceA = ' '.repeat(parentFields.length * 2 + 2);
    const spaceB = ' '.repeat(Math.max(leftWidth - name.length + 4, 4));
    const directiveName = `${spaceA}${name}${spaceB}`;
    let value = data[_field.name];
    if (multiple) {
        if (!Array.isArray(value)) value = [];

        if (type === 'block') {
            return value
                .map((item) => {
                    block.forEach((elm) => {
                        elm.lineNbr = null;
                    });
                    const comments = [];
                    const emptyLines = [];
                    if (Array.isArray(item.$order)) {
                        item.$order.forEach((elm) => {
                            if (`${elm.name}`.trim().toLowerCase().startsWith('comment_')) {
                                comments.push({
                                    name: elm.name,
                                    type: 'comment',
                                    value: item[elm.name],
                                    changed: true,
                                    lineNbr: elm.lineNbr
                                });
                            } else if (elm.name === 'empty-line') {
                                emptyLines.push({
                                    name: elm.name,
                                    type: 'empty-line',
                                    value: '',
                                    changed: true,
                                    lineNbr: elm.lineNbr
                                });
                            } else {
                                const itemBlock = block.find((e) => e.name === elm.name);
                                if (itemBlock) {
                                    itemBlock.lineNbr = elm.lineNbr;
                                }
                            }
                        });
                    }
                    const directives = generateCodeByBlock(
                        module,
                        [...block, ...comments, ...emptyLines],
                        item,
                        [...parentFields, _field]
                    );
                    if (!directives) return null;
                    const nameField = block.find((elm) => elm.isName);
                    return {
                        lineNbr: item.$lineNbr,
                        content: `${spaceA}<${name}${
                            nameField ? ` ${item[nameField.name] || ''}` : ''
                        }>\n${directives}\n${spaceA}</${name}>`
                    };
                })
                .filter((elm) => elm);
        }

        if (type === 'key-value') {
            let maxW = 0;
            const list = value
                .filter((item) => item.value?.key && item.value?.value)
                .map((item) => {
                    if (item.value.key.length > maxW) maxW = item.value.key.length;
                    return item;
                })
                .map(
                    (item) =>
                        `${spaceA}  ${item.value.key}${' '.repeat(
                            maxW - item.value.key.length + 2
                        )}${item.value.value}`
                )
                .filter((code) => code)
                .join('\n');
            if (!list) return [];
            return [
                {
                    lineNbr,
                    content: `${spaceA}<${name}>\n${list}\n${spaceA}</${name}>`
                }
            ];
        }

        if (type === 'host-port') {
            value = value
                .map((item) => ({
                    ...item,
                    host: [
                        `${item.value?.host || ''}`.trim(),
                        `${item.value?.port || ''}`.trim()
                    ].filter((v) => v)
                }))
                .filter((item) => item.host && item.host.length)
                .map((item) => ({ value: item.host.join(':'), $lineNbr: item.$lineNbr }));
        }

        if (Array.isArray(fields)) {
            return fields.map((item) => ({
                lineNbr: item.$lineNbr,
                content: `${directiveName}${item.value}`
            }));
        }

        const nonEmptyValues = value.filter((item) => `${item.value}`.trim().length);
        if (!nonEmptyValues.length) return [];

        if (separator) {
            return [
                {
                    lineNbr,
                    content: `${directiveName}${nonEmptyValues
                        .map((item) => item.value)
                        .join(separator)}`
                }
            ];
        }

        return value.map((item) => ({
            lineNbr: item.$lineNbr,
            content: `${directiveName}${item.value}`
        }));
    }
    switch (type) {
        case 'multiline-string':
            value = value
                .split('\n')
                .map(
                    (line) =>
                        `${' '.repeat(directiveName.length)}${line
                            .trimLeft()
                            .replace(/[\\]+$/, '')}`
                )
                .join('\\\n')
                .trim();
            break;
        case 'multiline-string-block': {
            value = value
                .split('\n')
                .filter((line) => line)
                .map((line) => ({ line, spaces: line.length - line.trimLeft().length }));

            const minLeftSpaces = value.reduce((v, elm) => {
                if (v === null) return elm.spaces;
                return Math.min(elm.spaces, v);
            }, null);

            value = value.map(
                ({ line, spaces }) => `${' '.repeat(spaces - minLeftSpaces)}${line.trimLeft()}`
            );

            value = value.map((line) => `${spaceA}  ${line}`).join('\n');
            return [
                {
                    lineNbr,
                    content: `${spaceA}<${name}>\n${value}\n${spaceA}</${name}>`
                }
            ];
        }
        case 'block': {
            const directives = generateCodeByBlock(module, block, value || {}, [
                ...parentFields,
                _field
            ]);
            if (!directives) return [];
            return [
                {
                    lineNbr,
                    content: `${spaceA}<${name}>\n${directives}\n${spaceA}</${name}>`
                }
            ];
        }
        case 'key-value': {
            if (!value.key || value.value) return [];
            return [
                {
                    lineNbr,
                    content: `${spaceA}<${name}>\n${spaceA}  ${value.key}${spaceB}${value.value}\n${spaceA}</${name}>`
                }
            ];
        }
        case 'comment':
            return [
                {
                    lineNbr,
                    content: value.trimLeft()
                }
            ];
        case 'empty-line':
            return [
                {
                    lineNbr,
                    content: ''
                }
            ];
        default:
            break;
    }
    return [
        {
            lineNbr,
            content: `${directiveName}${value}`
        }
    ];
}

function getModuleConfigCode(mdl) {
    const data = getModuleFieldsAsObject(mdl.module.fields);
    const directives = generateCodeByBlock(mdl.module, mdl.module.fields, data, []);
    const exec = mdl.actions.map((action) => getActionConfigCode(action, 2)).join('\n');
    const code = [];
    const TagName =
        mdl.tag ||
        {
            collect: 'Input',
            sendTo: 'Output',
            extension: 'Extension'
        }[mdl.module.type];

    code.push(`<${TagName} ${getModuleName(mdl)}>`);
    if (mdl.module.defaultDirectives) code.push(`  ${mdl.module.defaultDirectives}`);
    if (directives) code.push(directives);
    if (exec) code.push(exec);
    code.push(`</${TagName}>`);
    return {
        index: mdl.lineNbr,
        content: code.join('\n')
    };
}

function getRouteConfigCode() {
    return (route, i) => {
        const inputModules = route.collect.filter((item) => item.module);
        const outputModules = route.sendTo.filter((item) => item.module);
        const inputConfigCode = inputModules.map(getModuleConfigCode);
        const outputConfigCode = outputModules.map(getModuleConfigCode);
        const processors = Array.isArray(route.processors) ? route.processors : [];
        let routeCode = '';
        if (
            Array.isArray(route.content) &&
            route.content.find((elm) => `${elm.flag}`.toLowerCase().trim() === 'path')
        ) {
            const isRouteTag = `${route.tag || 'Route'}`.trim().toLowerCase() === 'route';
            const hasContent = inputModules.length || processors.length || outputModules.length;
            if (isRouteTag && hasContent) {
                routeCode = `<${route.tag || 'Route'} ${route.name || i + 1}>\n`;
                routeCode += route.content
                    .map((elm) => {
                        const space = ' '.repeat(
                            Math.max(2, elm.line ? elm.line.length - elm.line.trimLeft().length : 2)
                        );
                        if (`${elm.flag}`.toLowerCase().trim() === 'path') {
                            return `${space}Path ${inputModules.map(getModuleName).join(', ')} ${
                                processors.length ? '=> ' : ''
                            }${processors.join(' => ')} => ${outputModules
                                .map(getModuleName)
                                .join(', ')}`;
                        }
                        return `${space}${elm.line.trimLeft()}`;
                    })
                    .join('\n');
                routeCode += `\n</${route.tag || 'Route'}>`;
            }
        } else {
            routeCode =
                inputModules.length && outputModules.length
                    ? `<Route ${route.name || i + 1}>
  Path ${inputModules.map(getModuleName).join(', ')} ${
                          processors.length ? '=> ' : ''
                      }${processors.join(' => ')} => ${outputModules.map(getModuleName).join(', ')}
</Route>`
                    : '';
        }
        const result = [...inputConfigCode, ...outputConfigCode];
        if (routeCode) result.push({ index: route.lineNbr, content: routeCode });
        return result;
    };
}

function generateExtensionCode(module) {
    if (module.content) {
        return {
            index: module.lineNbr,
            content: `<Extension ${module.name}>\n${module.content}\n</Extension>`
        };
    }
    return getModuleConfigCode({ module, actions: [], lineNbr: module.lineNbr });
}

function generateConfigCode(ConfigItem, options) {
    try {
        const routesConfigCode = ConfigItem.routes
            .map(getRouteConfigCode(options))
            .reduce((acc, item) => [...acc, ...item], []);
        const inputModules = ConfigItem.routes
            .map((route) => route.collect.filter((item) => item.module))
            .reduce((acc, t) => [...acc, ...t], []);
        const outputModules = ConfigItem.routes
            .map((route) => route.sendTo.filter((item) => item.module))
            .reduce((acc, t) => [...acc, ...t], []);
        const extentionsFromActions = [];
        [...inputModules, ...outputModules].map((mdl) =>
            getExtentions(mdl.actions, extentionsFromActions, mdl, ConfigItem)
        );
        if (!Array.isArray(ConfigItem.directives)) ConfigItem.directives = [];
        const dirNames = ConfigItem.directives.map((d) => `${d.name}`.trim().toLowerCase());
        if (!dirNames.includes('loglevel'))
            ConfigItem.directives.push({ name: 'LogLevel', content: 'INFO', lineNbr: -2 });
        if (!dirNames.includes('logfile'))
            ConfigItem.directives.push({ name: 'LogFile', content: '%MYLOGFILE%\n', lineNbr: -1 });
        ConfigItem.directives = ConfigItem.directives.filter(
            ({ name, content }) => name && content
        );
        let generatedConfig = [...routesConfigCode, ...extentionsFromActions];
        if (ConfigItem.directives.length)
            generatedConfig.push(
                ...ConfigItem.directives.map(({ name, content, lineNbr }) => ({
                    index: lineNbr,
                    content: `${name}  ${content}`
                }))
            );
        if (ConfigItem.extensions)
            generatedConfig.push(...ConfigItem.extensions.map(generateExtensionCode));
        if (ConfigItem.processors)
            generatedConfig.push(
                ...ConfigItem.processors.map(({ tag, name, content, lineNbr }) => ({
                    index: lineNbr,
                    content: `<${tag || 'Processor'} ${name}>\n${content}\n</${tag || 'Processor'}>`
                }))
            );
        if (ConfigItem.comments)
            generatedConfig.push(
                ...ConfigItem.comments.map(({ content, lineNbr }) => ({ index: lineNbr, content }))
            );
        if (ConfigItem.emptyLines)
            generatedConfig.push(
                ...ConfigItem.emptyLines.map(({ lineNbr }) => ({ index: lineNbr, content: '' }))
            );
        generatedConfig = generatedConfig.map((elm) => {
            elm.index = [null, undefined].includes(elm.index) ? Infinity : elm.index;
            return elm;
        });
        generatedConfig.sort((a, b) => a.index - b.index);
        return `${generatedConfig.map((elm) => elm.content).join('\n')}`
            .replace(/<\/(\w+)>\n<(\w+[^>]+)>/g, '</$1>\n\n<$2>')
            .trim();
    } catch (error) {
        /**/
    }
    return '';
}

export default generateConfigCode;
