import GENERAL_DIRECTIVES from '../generalDirectives';

export const ImLinuxaudit = {
    label: 'Linux Audit System',
    name: 'im_linuxaudit',
    type: 'collect',
    defaultDirectives: 'Module      im_linuxaudit',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'LoadRule',
            type: 'string',
            multiple: true,
            defaultValue: [{ value: '' }]
        },
        {
            name: 'ResolveValues',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'Rules',
            type: 'multiline-string-block',
            defaultValue: ''
        },
        {
            name: 'Include',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'LockConfig',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
