import GENERAL_DIRECTIVES from '../generalDirectives';

export const ImGooglelogging = {
    label: 'Google Cloud Logging',
    name: 'im_googlelogging',
    type: 'collect',
    defaultDirectives: 'Module      im_googlelogging',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'CredentialsFile',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'ResourceName',
            type: 'string',
            defaultValue: [{ value: '' }],
            multiple: true,
            required: true
        },
        {
            name: 'Filter',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'OrderBy',
            type: 'select',
            options: ['timestamp asc', 'timestamp desc'],
            defaultValue: 'timestamp asc'
        },
        {
            name: 'PollInterval',
            type: 'number',
            defaultValue: 20
        },
        {
            name: 'Reconnect',
            type: 'number',
            defaultValue: ''
        },
        {
            name: 'ReadFromLast',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'SavePos',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
